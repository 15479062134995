import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AccompanistContext } from "./AccompanistContext";
import AdditionalOptions from "./IndividualOptions";
import { validateAge } from "../helper";
import axiosInstance from "../../../../services/AxiosInstance";
import Loader from "../Loader";
import Districts from "../Districts";
import { mediaRequiredCategories } from "./Categories";

const Individual = () => {
  const { accompanists, initializeAccompanists } =
    useContext(AccompanistContext);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDistrict, setSelectedDistricts] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    fatherName: "",
    dob: "",
    mobile: "",
    email: "",
    address: "",
    photo: null,
    certificate: null,
    district: "",
    selectedCategory: "",
    media: null,
    accompanists: [],
  });
  const [districts, setDistricts] = useState([]);
  useEffect(() => {
    initializeAccompanists(0);
    const getAllDistricts = async () => {
      await axiosInstance.get("/districts").then((res) => {
        setDistricts(res.data);
      });
    };
    getAllDistricts();
  }, [initializeAccompanists]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  function handleMediaChange(e, name) {
    const file = e.target.files[0];
    if (file) {
      const validTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "video/mp4",
        "video/avi",
        "video/mkv",
        "video/mov",
        "video/wmv",
        "video/flv",
        "video/webm",
        "video/m4v",
        "video/3gp",
        "audio/mp3",
        "audio/wav",
        "audio/ogg",
        "audio/aac",
      ];

      const maxSize = 100 * 1024 * 1024; // 100MB in bytes

      // Check if the file type is valid
      if (!validTypes.includes(file.type)) {
        Swal.fire({
          title: "<strong>Invalid File Type</strong>",
          icon: "info",
          html: `The file type must be one of the following: JPG, PNG, GIF, MP4, AVI, MKV, MOV, WMV, FLV, WEBM, M4V, 3GP, MP3, WAV, OGG, AAC.`,
          showCloseButton: true,
        });
        e.target.value = ""; // Clear the selected file
        return;
      }

      // Check if the file size exceeds the limit
      if (file.size > maxSize) {
        Swal.fire({
          title: "<strong>File Size Exceeds</strong>",
          icon: "info",
          html: `File size must be less than 100MB.`,
          showCloseButton: true,
        });
        e.target.value = ""; // Clear the selected file
        return;
      }

      // Check if the file is an image (no duration check needed)
      if (file.type.startsWith("image/")) {
        // Set the file directly in state if it's an image
        setFormData((prev) => ({
          ...prev,
          [name]: file,
        }));
        return;
      }

      // For audio and video files, check duration
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        const blob = new Blob([event.target.result]);
        const url = URL.createObjectURL(blob);
        let mediaElement;

        // Determine if it's an audio or video file
        if (file.type.startsWith("audio/")) {
          mediaElement = new Audio(url);
        } else if (file.type.startsWith("video/")) {
          mediaElement = document.createElement("video");
          mediaElement.src = url;
        }

        mediaElement.onloadedmetadata = () => {
          const duration = mediaElement.duration; // Get duration in seconds
          if (duration > 60) {
            Swal.fire({
              title: "<strong>Duration Exceeds</strong>",
              icon: "info",
              html: `The length of the audio or video must be less than 1 minute.`,
              showCloseButton: true,
            });
            e.target.value = ""; // Clear the selected file
            return;
          }

          // If all validations pass, set the file in state
          setFormData((prev) => ({
            ...prev,
            [name]: file, // Dynamically set the appropriate field in state
          }));
        };

        // Cleanup the URL object
        mediaElement.onended = () => {
          URL.revokeObjectURL(url);
        };
        mediaElement.load(); // Load the media to get metadata
      };
      fileReader.readAsArrayBuffer(file);
    }
  }
  function handleFileChange(e, name) {
    const file = e.target.files[0];

    if (file) {
      let validTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (name == "photo") {
        validTypes = ["image/jpeg", "image/png"];
      }
      const maxSize = 1 * 1024 * 1024; // 1MB in bytes

      if (!validTypes.includes(file.type)) {
        // alert("Only JPG, PNG, and PDF files are allowed.");
        Swal.fire({
          title: "<strong>Invalid File Type</strong>",
          icon: "info",
          html: `
            File Type must be JPG${
              name === "certificate" ? ", PDF, or" : " or"
            } PNG.
             `,
          showCloseButton: true,
        });
        e.target.value = ""; // Clear the selected file
        return;
      }

      if (file.size > maxSize) {
        // alert("File size must be less than or equal to 1MB.");
        Swal.fire({
          title: "<strong>File Size Exceeds</strong>",
          icon: "info",
          html: `
            File size must be less than or equal to 1MB
          `,
          showCloseButton: true,
        });
        e.target.value = "";
        return;
      }
      if (name == "certificate") {
        setFormData((prev) => ({
          ...prev,
          certificate: file,
        }));
      }
      if (name == "photo") {
        setFormData((prev) => ({
          ...prev,
          photo: file,
        }));
      }
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCategory) {
      setError("Category selection is required.");
      return;
    }
    // if (!validateAge(formData.dob)) {
    //   Swal.fire({
    //     title: "Invalid Age?",
    //     text: "Age must be between 15 to 29 years",
    //     icon: "question",
    //   });
    //   return;
    // }
    if (mediaRequiredCategories.includes(selectedCategory) && !formData.media) {
      Swal.fire({
        title: "Upload Media",
        text: "Media is required for the mentioned category.",
        icon: "error",
      });
      return;
    }

    if (!formData.district) {
      Swal.fire({
        title: "District is Required",
        text: "Select District from Dropdown",
        icon: "error",
      });
      return;
    }

    for (const accompanist of accompanists) {
      // if (accompanist.dob && !validateAge(accompanist.dob)) {
      //   Swal.fire({
      //     title: "Invalid Accompanist Age",
      //     text: "All accompanists must be between 15 to 29 years old.",
      //     icon: "error",
      //   });
      //   return;
      // }
      if (!accompanist.district) {
        Swal.fire({
          title: "District is Required",
          text: "Select District from Dropdown",
          icon: "error",
        });
        return;
      }
    }
    const formDataToSubmit = new FormData();
    console.log(formData);
    formDataToSubmit.append("name", formData.name);
    formDataToSubmit.append("fatherName", formData.fatherName);
    formDataToSubmit.append("dob", formData.dob);
    formDataToSubmit.append("mobile", formData.mobile);
    formDataToSubmit.append("email", formData.email);
    formDataToSubmit.append("address", formData.address);
    formDataToSubmit.append("category", selectedCategory);
    formDataToSubmit.append("district", formData.district)
    // if (formData.certificate) {
    formDataToSubmit.append("media", formData.media);
    formDataToSubmit.append("certificate", formData.certificate);
    formDataToSubmit.append("photo", formData.photo);
    // }

    accompanists.forEach((accompanist, index) => {
      formDataToSubmit.append(`accompanists[${index}][name]`, accompanist.name);
      formDataToSubmit.append(`accompanists[${index}][district]`, accompanist.district);
      formDataToSubmit.append(
        `accompanists[${index}][mobile]`,
        accompanist.mobile
      );
      formDataToSubmit.append(`accompanists[${index}][dob]`, accompanist.dob);
      // if (accompanist.document) {
      formDataToSubmit.append(
        `accompanists[${index}][document]`,
        accompanist.document
      );
      formDataToSubmit.append(
        `accompanists[${index}][photo]`,
        accompanist.photo
      );
      // }
    });

    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "/submit-individual",
        formDataToSubmit,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      Swal.fire({
        title: "Success!",
        text: "Application Filled Successfully!",
        icon: "success",
      });
      setError("");
      navigate("/page-creative");
    } catch (error) {
      console.error("Error submitting form data:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.errors?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-center align-items-center mb-2">
            <h3>Individual Registration Form</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4 d-flex justify-content-between align-items-center">
              <h4>
                <label>Personal Information </label>
              </h4>
              <span className="text-red">* marked fields are required </span>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      Name of Participant<span className="text-red">*</span>
                    </strong>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    required
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      Father's Name<span className="text-red">*</span>
                    </strong>
                  </label>
                  <input
                    type="text"
                    required
                    name="fatherName"
                    className="form-control"
                    placeholder="Father's Name"
                    value={formData.fatherName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      Date of Birth<span className="text-red">*</span>
                    </strong>
                  </label>
                  <input
                    type="date"
                    required
                    name="dob"
                    className="form-control"
                    value={formData.dob}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      Mobile Number<span className="text-red">*</span>
                    </strong>
                  </label>
                  <input
                    type="number"
                    name="mobile"
                    required
                    className="form-control"
                    placeholder="Mobile Number"
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3 ">
                  <div className="d-flex justify-content-between">
                    <label className="mb-1">
                      <strong>
                        Upload Photo<span className="text-red">*</span>
                      </strong>
                    </label>
                    <label className="">.jpg, .jpeg, .png, .pdf (≤ 1 MB)</label>
                  </div>
                  <input
                    type="file"
                    placeholder="Certificate"
                    className="form-control"
                    required
                    accept=".jpg,.jpeg,.png,.pdf"
                    onChange={(e) => handleFileChange(e, "photo")}
                  />
                </div>
              </div>
            </div>

            <div className="row justify-content-between">
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      Email
                    </strong>
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="form-control"
                    // required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3 ">
                  <div className="d-flex justify-content-between">
                    <label className="mb-1">
                      <strong>
                        Upload Media of Competition
                      </strong>
                    </label>
                    <label className="">
                      Upload Audio, Video or Image (≤ 100 MB)
                    </label>
                  </div>
                  <input
                    type="file"
                    placeholder="Select a File"
                    className="form-control"
                    // required
                    accept=".jpg,.jpeg,.png,.gif,.mp4,.avi,.m4v,.mkv,.mov,.wmv,.flv,.webm,.m4v,.3gp,.mp3,.wav,.ogg,.aac"
                    onChange={(e) => handleMediaChange(e, "media")}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-8">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      Complete Address<span className="text-red">*</span>
                    </strong>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    required
                    className="form-control"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      District<span className="text-red">*</span>
                    </strong>
                  </label>
                  {districts && (
                    <Districts
                      setFormData={setFormData}
                      districts={districts}
                      selectedDistrict={selectedDistrict}
                      setSelectedDistricts={setSelectedDistricts}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <div className="d-flex justify-content-between">
                    <label className="mb-1">
                      <strong>
                        Upload Certificate (Birth Certificate/High School
                        Certificate School/College/University Score Card ID
                        Proof)<span className="text-red">*</span>
                      </strong>
                    </label>
                    <label className="">.jpg, .jpeg, .png, .pdf (≤ 1 MB)</label>
                  </div>
                  <input
                    type="file"
                    placeholder="Certificate"
                    className="form-control"
                    required
                    onChange={(e) => handleFileChange(e, "certificate")}
                  />
                </div>
              </div>
            </div>

            <div className="mb-4 mt-4">
              <h4>
                <label>
                  Competition Details<span className="text-red">*</span>{" "}
                </label>
              </h4>
            </div>
            <AdditionalOptions
              setFormData={setFormData}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              districts={districts}
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className="d-flex justify-content-center mt-4">
              <button type="submit" className="btn btn-primary btn-block">
                Submit
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default Individual;
