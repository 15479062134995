const validateAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  const age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();

  // Adjust the age if the birth date hasn't occurred yet this year
  const adjustedAge =
    monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)
      ? age - 1
      : age;

  // Check if age is within the range
  return adjustedAge >= 15 && adjustedAge <= 29;
};
export { validateAge };
