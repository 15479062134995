import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

function Creative(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div
      className="authincation p-meddle"
      style={{ overflowY: "auto", maxHeight: "100vh" }}
    >
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="mt-4 mb-4">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div
                    className="auth-form"
                    style={{
                      minHeight: "90vh",
                      height: "auto",
                      overflowY: "auto",
                    }}
                  >
                    {location.pathname != "/page-creative" && (
                      <div className="d-flex mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />
                          &nbsp;&nbsp;&nbsp;Back
                        </button>
                      </div>
                    )}
                    <div className="text-center mb-5">
                      <Link to="/page-creative">
                        <img
                          src="./youva.png"
                          alt="Logo"
                          style={{
                            height: "125px",
                            width: "200px",
                          }}
                        />
                      </Link>
                    </div>
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Creative);
