import React, { createContext, useState, useCallback } from "react";

// Create the context
export const TeamMemberContext = createContext();

// Provider component
export const TeamMemberProvider = ({ children }) => {
  const [teamMember, setTeamMember] = useState([]);

  // Function to update an individual accompanist's details
  const updateTeamMember = useCallback((index, updatedData) => {
    setTeamMember((prev) => {
      const newTeamMember = [...prev];
      newTeamMember[index] = { ...newTeamMember[index], ...updatedData };
      return newTeamMember;
    });
  }, []);

  // Function to initialize or reset the accompanists array
  const initializeTeamMember = useCallback((num) => {
    setTeamMember(Array.from({ length: 9 }, () => ({
      name: "",
      mobile: "",
      dob: "",
      document: null,
      photo: null,
      district: ""
    })));
  }, []);

  return (
    <TeamMemberContext.Provider
      value={{
        teamMember,
        updateTeamMember,
        initializeTeamMember,
      }}
    >
      {children}
    </TeamMemberContext.Provider>
  );
};
