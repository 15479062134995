import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const MOCK_DATA = [
  {
    id: 1,
    name: "dsfs",
    email: "aman@gmail.com",
    category: "folkSong",
    // "teamName": "dfds"
  },
  {
    id: 2,
    name: "dflsjl",
    isLeader: true,
    category: "folkSong",
    // "teamName": "dfds"
  },
];

const INDIVIDUAL_COLUMNS = [
  { Header: "Sr No.", footer: "Sr No.", accessor: "serialNumber" },
  { Header: "Name", footer: "Name", accessor: "name" },
  { Header: "Category", footer: "Category", accessor: "category" },
  { Header: "Accompanists", footer: "Accompanists", accessor: "accompanists" },
  { Header: "Email", footer: "Email", accessor: "email" },
  { Header: "Mobile", footer: "Mobile", accessor: "mobile" },
  { Header: "Date of Birth", footer: "Date of Birth", accessor: "dob" },
  { Header: "District", footer: "District", accessor: "district" },
  {
    Header: "Detail",
    footer: "Detail",
    accessor: "id",
    Cell: ({ row }) => {
      const navigate = useNavigate();
      const id = row.original.id; // Access the ID of the current row
      // console.log(row.original.id)
      return (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => navigate(`${id}`)}
        >
          View
        </button>
      );
    },
  }
  
];

export { INDIVIDUAL_COLUMNS, MOCK_DATA };
