import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';

const MultiSelectFilter = ({ filterKey, handleFilterChange, selectedValues, apiUrl }) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await axios.get(apiUrl);
                const data = response.data.data;
                
                setOptions(data)
                console.log("hello format object do")
                
            } catch (error) {
                console.error('Error fetching filter options:', error);
            }
        };

        fetchOptions();
    }, [apiUrl]);

    const handleSelect = (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        handleFilterChange(filterKey, selectedValues);
    };

    return (
        <div className="d-flex justify-content-start mb-3" style={{ minWidth: '200px',width: '100%' }}>
            <Select
                isMulti
                name="filters"
                options={options}
                className="basic-multi-select w-100"
                classNamePrefix="select"
                placeholder="Select options..."
                value={options.filter(option => selectedValues.includes(option.value))}
                onChange={handleSelect}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary: '#7356f1',
                    },
                })}
            />
        </div>
    );
};

export default MultiSelectFilter;