export const CompetitionCategories = [
    { value: "folkDance", label: "Cultural Category - Individual Folk Dance" },
    { value: "folkSong", label: "Cultural Category - Individual Folk Song" },
    { value: "classicalDance", label: "Cultural Category - Classical Dance" },
    { value: "classicalSinging", label: "Cultural Category - Classical Singing" },
    {
      value: "classicalInstrumental",
      label: "Cultural Category - Classical Instrumental",
    },
    { value: "yogabhyas", label: "Cultural Category - Yogabhyas" },
    { value: "poetry", label: "Life Skill Category - Poetry" },
    { value: "storyWriting", label: "Life Skill Category - Story Writing" },
    { value: "painting", label: "Life Skill Category - Painting" },
    { value: "declamation", label: "Life Skill Category - Declamation" },
    { value: "photography", label: "Life Skill Category - Photography" },
    { value: "handicraft", label: "Yuva Kriti Category - Handicraft" },
    { value: "textile", label: "Yuva Kriti Category - Textile" },
    { value: "agroProducts", label: "Yuva Kriti Category - Agro Products" },
    { value: "strongMan", label: "Traditional Sports - Strong Man" },
    { value: "strongWomen", label: "Traditional Sports - Strong Women" },
    { value: "armWrestling", label: "Traditional Sports - Arm Wrestling" },
    { value: "mallakhamb", label: "Traditional Sports - Mallakhamb" },
    { value: "pittu", label: "Traditional Sports - Pittu" },
    { value: "murgaJapat", label: "Traditional Sports - Murga Japat" },
    { value: "pickleball", label: "Pickleball" },
    { value: "rollerSkating", label: "Roller Skating" },
  ];
  
  export const mediaRequiredCategories = [
    "folkDance",
    "folkSong",
    "classicalDance",
    "classicalSinging",
    "classicalInstrumental",
    "yogabhyas",
    "poetry",
    "storyWriting",
    "painting",
    "declamation",
    "photography",
    "handicraft",
    "textile",
    "agroProducts",
  ];
  