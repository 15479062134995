import React from 'react';
import Select from 'react-select';

const NullCheckFilter = ({ filterFields, handleFilterChange }) => {
    // Convert filterFields to react-select format
    const options = filterFields.map(field => ({
        value: field.key,
        label: field.label
    }));

    const customStyles = {
        control: (base, state) => ({
            ...base,
            minHeight: '38px',
            background: '#fff',
            borderColor: state.isFocused ? '#7356f1' : '#e4e4e4',
            boxShadow: state.isFocused ? '0 0 0 1px #7356f1' : null,
            '&:hover': {
                borderColor: state.isFocused ? '#7356f1' : '#e4e4e4'
            }
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: '#7356f1',
            borderRadius: '4px',
        }),
        multiValueLabel: (base) => ({
            ...base,
            color: 'white',
            padding: '0 6px',
        }),
        multiValueRemove: (base) => ({
            ...base,
            color: 'white',
            ':hover': {
                backgroundColor: '#5e41d2',
                color: 'white',
            },
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#7356f1' : state.isFocused ? '#f3f0fe' : null,
            ':active': {
                backgroundColor: '#7356f1',
            }
        }),
        menu: (base) => ({
            ...base,
            zIndex: 9999
        })
    };

    const handleSelect = (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        handleFilterChange(selectedValues);
    };

    return (
        <div className="d-flex justify-content-start mb-3" style={{ minWidth: '200px', width: '100%' }}>
            <Select
                isMulti
                name="nullCheck"
                options={options}
                className="basic-multi-select w-100"
                classNamePrefix="select"
                placeholder="Check Empty Fields..."
                onChange={handleSelect}
                styles={customStyles}
                isClearable={true}
                isSearchable={true}
                closeMenuOnSelect={false}
                components={{
                    IndicatorSeparator: () => null
                }}
            />
        </div>
    );
};

export default NullCheckFilter;