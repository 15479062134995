import React, { useContext, useState } from "react";
import { AccompanistContext } from "./AccompanistContext";
import { validateAge } from "../helper";
import Swal from "sweetalert2";
import Districts from "../Districts";

const AccompanistDetail = ({ index, districts }) => {
  const { accompanists, updateAccompanist } = useContext(AccompanistContext);
  const [selectedDistrict, setSelectedDistricts] = useState("");
  const singleAccompanist = accompanists[index] || {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateAccompanist(index, { [name]: value });
    // console.log(accompanists);
  };
  const handleFileChange = (e, name) => {
    const file = e.target.files[0];
    if (file) {
      let validTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (name == "photo") {
        validTypes = ["image/jpeg", "image/png"];
      }
      const maxSize = 1 * 1024 * 1024;

      if (!validTypes.includes(file.type)) {
        Swal.fire({
          title: "<strong>Invalid File Type</strong>",
          icon: "info",
          html: `
            File Type must be JPG${name === "certificate" ? ", PDF, or" : " or"} PNG.
             `,
          showCloseButton: true,
        });
        e.target.value = "";
        return;
      }

      if (file.size > maxSize) {
        Swal.fire({
          title: "<strong>File Size Exceeds</strong>",
          icon: "info",
          html: `File size must be ≤ 1MB`,
          showCloseButton: true,
        });
        e.target.value = "";
        return;
      }
      if (name == "photo") {
        updateAccompanist(index, { photo: file });
      } else {
        updateAccompanist(index, { document: file });
      }
    }
  };
  return (
    <div key={index} className="form-group mt-2">
      <label>
        <strong>Accompanist {index + 1}:</strong>
      </label>
      <div className="row justify-content-between">
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label className="mb-1">
              <strong>
                Name<span className="text-red">*</span>
              </strong>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="name"
              required
              value={singleAccompanist.name || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label className="mb-1">
              <strong>
                Mobile Number<span className="text-red">*</span>
              </strong>
            </label>
            <input
              type="number"
              className="form-control"
              placeholder="Mobile Number"
              name="mobile"
              required
              value={singleAccompanist.mobile || ""}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="row justify-content-between">
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label className="mb-1">
              <strong>
                DOB<span className="text-red">*</span>
              </strong>
            </label>
            <input
              type="date"
              className="form-control"
              placeholder="DOB"
              name="dob"
              required
              value={singleAccompanist.dob || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label className="mb-1 d-flex justify-content-between">
              <strong>
                Upload Photo
                <span className="text-red">*</span>
              </strong>
              <span>.jpg, .jpeg, .png (≤ 1 MB)</span>
            </label>
            <input
              type="file"
              className="form-control"
              placeholder="Document"
              required
              name="document"
              onChange={(e) => handleFileChange(e, "photo")}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label className="mb-1 d-flex justify-content-between">
              <strong>
                Upload Document (Birth Certificate/High School Certificate
                School/College/University Score Card ID Proof)
                <span className="text-red">*</span>
              </strong>
              <span>.jpg, .jpeg, .png, .pdf (≤ 1 MB)</span>
            </label>
            <input
              type="file"
              className="form-control"
              placeholder="Document"
              required
              name="document"
              onChange={(e) => handleFileChange(e, "certificate")}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label className="mb-1">
              <strong>
                District<span className="text-red">*</span>
              </strong>
            </label>
            {districts && (
              <Districts
                // setFormData={setFormData}
                districts={districts}
                selectedDistrict={selectedDistrict}
                setSelectedDistricts={setSelectedDistricts}
                source = {{name:"accompanists",index: index}}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccompanistDetail;
