import React from 'react'
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import swal from "sweetalert";
const RemarkModal = ({ remarkshowModal, setRemarkshowModal }) => {
    return (
        <>
            <Modal className="fade" show={remarkshowModal}>
                <Modal.Header>
                    <Modal.Title>Landmark - Remarks</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setRemarkshowModal(false)}
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 pt-3">
                        <textarea
                            name="write-email"
                            id="write-email"
                            cols="30"
                            rows="5"
                            className="form-control"
                            placeholder="It's really an amazing.I want to know more about it..!"
                        ></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <button
                        onClick={() =>
                            swal({
                                title: "Confirm!",
                                text:
                                    "Are you Sure",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                            }).then((willDelete) => {
                                if (willDelete) {
                                    swal("Poof! Your imaginary file has been deleted!", {
                                        icon: "success",
                                    });
                                } else {
                                    swal("Your imaginary file is safe!");
                                }
                            })
                        }
                        className="btn btn-warning btn sweet-confirm"
                    >
                       Remarks
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RemarkModal