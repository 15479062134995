import React, { useContext, useEffect } from "react";
import { TeamMemberContext } from "./TeamMemberContext";
import TeamMemberDetail from "./TeamMemberDetail";

const TeamMembers = ({districts}) => {
  const { teamMember, initializeTeamMember } = useContext(TeamMemberContext);
  useEffect(() => {
    initializeTeamMember(9); // Initializes team members only once on mount
  }, [initializeTeamMember]);
  return (
    <div className="form-group mt-5">
      <label>
        <strong>Team Members:</strong>
      </label>
      {teamMember.map((_, index) => (
        <TeamMemberDetail districts={districts} key={index} index={index} />
      ))}
    </div>
  );
};

export default TeamMembers;
