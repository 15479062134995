import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import PageTitle from '../../../jsx/layouts/PageTitle'
import { Link } from 'react-router-dom'
import ImportExcelModal from './ImportExcelModal'
import { getAdminCategoryType } from '../../../services/adminApiService/adminCategoryType/AdminCategoryTypeApi'
import { getOrganisationList } from '../../../services/adminApiService/organisationApi/OrganisationApiService'

const OrganizationList = () => {
    const [showImportModal, setShowImportModal] = useState(false);
    const [adminCategoryTypeData, setAdminCategoryTypeData] = useState([]);
    const [organisationdataList, setOrganisationdataList] = useState([]);

    //console.log("organisationdataList===>", organisationdataList);
   


    const getOrganisationListApi = async () => {

        try {

            const response = await getOrganisationList();
            setOrganisationdataList(response?.data)

        } catch (error) {
            console.error('Login error:', error);
        }
    }
    useEffect(() => {
        getOrganisationListApi();
    }, []);


    const getAdminCategoryTypeListApi = async () => {

        try {

            const response = await getAdminCategoryType();
            setAdminCategoryTypeData(response?.data)

        } catch (error) {
            console.error('Login error:', error);
        }
    }
    useEffect(() => {
        getAdminCategoryTypeListApi();
    }, []);
    return (
        <>
            <PageTitle activeMenu="Table" motherMenu="Post" />
            <div className="col-12">

                <div className="card">
                    <div className="card-header d-flex">
                        <div>
                            <div className="input-group search-area">
                                <input type="text"
                                    className={`form-control `}
                                    placeholder="Search here..."
                                />
                                <span className="input-group-text" >
                                    <Link to={"#"}><i className="flaticon-381-search-2"></i></Link>
                                </span>
                            </div>
                        </div>
                        <div>
                            <Button variant="primary" className="mx-2" onClick={() => setShowImportModal(true)} >Import Excel  <i className="fa fa-plus" ></i></Button>
                            
                        </div>

                    </div>
                    <div className="card-body">
                        <div className="w-100 table-responsive">
                            <div id="example_wrapper" className="dataTables_wrapper">
                                <form >
                                    <table id="example" className="display w-100 dataTable">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>legal entity</th>
                                                <th>reg. no</th>
                                                <th> Name of insitution</th>
                                                <th>Contact Person Email</th>
                                                <th>Contact Person Mobile</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {organisationdataList?.data?.length > 0 ? (
                                                organisationdataList.data.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                             <>
                                                                    <td>{data?.id}</td>
                                                                    <td>{data?.name_of_legel_entity}</td>
                                                                    <td>{data?.registration_number}</td>
                                                                    <td>{data?.name_of_organization}</td>
                                                                    <td>{data?.email_id}</td>
                                                                    <td>{data?.mobile}</td>
                                                                    <td>{data?.status === "1" ? "Active":"InActive"}</td>
                                                                   
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <Button style={{ whiteSpace: 'nowrap' }}> View More </Button>
                                                                            <Button className="btn btn-secondary shadow   mx-2" style={{ whiteSpace: 'nowrap' }}> Edit </Button>

                                                                        </div>
                                                                    </td>
                                                                </>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="6" className="text-center">Data is not available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>





            </div>


            <ImportExcelModal showImportModal={showImportModal} setShowImportModal={setShowImportModal} adminCategoryTypeData={adminCategoryTypeData} getOrganisationListApi={getOrganisationListApi}/>
        </>
    )
}

export default OrganizationList