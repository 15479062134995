





export const SuperAdminMenuList = [
    //Dashboard
    {
        title: 'Dashboard',	
        classsChange: 'mm-collapse',		
        iconStyle: <i className="fas fa-home"></i>,
       
    },    
    {
        title : "Form Data",
        classsChange: 'mm-collapse',
        // update:"New",
        iconStyle: <i className="fas fa-table" />,
        open: true, 
        content:[
            {
                title: "Competition Table",
                to : 'competition-table'
            },
            {
                title: "Team Entries",
                to : 'team-entries'
            },
            {
                title: "Individual Entries",
                to : 'individual-entries'
            }
           
        ],
    },

    // {
    //     title : "users Category",
    //     classsChange: 'mm-collapse',
    //     // update:"New",
    //     iconStyle: <i className="fas fa-users" />,
    //     content:[
    //         // {
    //         //     title:'User Level',
    //         //     to:'user-level'
    //         // },
    //         // {
    //         //     title:'User category',
    //         //     to:'user-category'
    //         // },
    //         {
    //             title:'User Category Type',
    //             to:'user-category-type'
    //         },
    //         {
    //             title:'User Sub Category',
    //             to:'user-sub-category'
    //         },
           
    //     ],
    // },
   
    
]



export const AdminMenuList = [
    //Dashboard
    {
        title: 'Dashboard',	
        classsChange: 'mm-collapse',		
        iconStyle: <i className="fas fa-home"></i>,
       
    },  
    
    {
        title : "Manage Registration",
        classsChange: 'mm-collapse',
        // update:"New",
        iconStyle: <i className="fas fa-users" />,
        content:[
            {
                title:'Insititute List',
                to:'Organization'
            },
            {
                title:'Individual  List',
                to:'Individual'
            },
            
           
        ],
    },
   
   
    
]