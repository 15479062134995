import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const LandingForm = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const handleIndividualClick = () => {
    navigate("individual"); // Redirect to /individual
  };

  const handleTeamClick = () => {
    navigate("team"); // Redirect to /team
  };

  return (
    <form
      onSubmit={onSubmit}
      className="p-4"
      style={{ height: "60vh" }}
    >
      <div className="mb-4">
        <h4 className="text-center" style={{ color: "#2c3e50" }}>
          Select Participation Type
        </h4>
      </div>
      <div className="row justify-content-between">
        <div className="col-md-12">
          <div className="form-group mb-3">
            <div className="text-center mt-3">
              <button
                type="button" // Change type to button
                className="btn btn-primary btn-md custom-btn w-100"
                onClick={handleIndividualClick} // Add click handler
              >
                Individual
              </button>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6">
          <div className="form-group mb-3">
            <div className="text-center mt-3">
              <button
                type="button" // Change type to button
                className="btn btn-primary btn-md custom-btn w-100"
                onClick={handleTeamClick} // Add click handler
              >
                Team
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </form>
  );
};

export default LandingForm;
