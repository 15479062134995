import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTable, usePagination } from "react-table";
import axios from "axios";
import { CompetitionCategories } from "../../Creative/Team/Categories";
import MultiSelectFilter from "./MultiSelectFilter";
const DEFAULT_PAGE_SIZE = 10;
const API_URL = `${process.env.REACT_APP_BACKENE_BASE_URL}/admin/team`;

const TeamEntryTableBody = ({ columns }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalTeams, setTotalTeams] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [filters, setFilters] = useState({});

  const fetchTableData = useCallback(async (pageIndex) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('access_token');
      
      const queryParams = {
        page: pageIndex + 1,
        limit: DEFAULT_PAGE_SIZE,
      };

      Object.entries(filters).forEach(([key, values]) => {
        if (values && values.length > 0) {
          queryParams[key] = values.join(',');
        }
      });

      const response = await axios.get(API_URL, {
        params: queryParams,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const { data, totalPages, totalItems, message, status } = response.data;


      if (!status) throw new Error(message || 'Failed to fetch data');
      setData(data || []);
      console.log(data);
      setTotalPages(totalPages);
      setTotalTeams(totalItems);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(error.message);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchTableData(currentPageIndex);
  }, [fetchTableData, currentPageIndex, filters]);

  const processedData = useMemo(() => {
    if (!data || !Array.isArray(data)) return [];
    return data.map((team, teamIndex) => ({
      id: team.id,
      serialNumber: (currentPageIndex * DEFAULT_PAGE_SIZE) + teamIndex + 1,
      leader: team.members && Array.isArray(team.members)
        ? team.members.find((member) => member.isLeader)?.name || 'No leader'
        : 'No leader',
      members: team.members && Array.isArray(team.members)
        ? team.members.filter((member) => !member.isLeader)
          .map(member => member.name)
          .join(', ') || 'No Members'
        : 'No Members',
      email: team.email || "NA",
      mobileNumber: team.mobileNumber || "NA",
      dob: team.dob || "NA",
      district: team.district || "NA",
      category: CompetitionCategories.filter(com => com.value == team.category)[0]?.label,
      teamName: team.teamName || "NA"
    }));
  }, [data, currentPageIndex]);

  const tableInstance = useTable(
    {
      columns,
      data: processedData,
      initialState: { pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE },
      manualPagination: true,
      pageCount: totalPages,
      autoResetPage: false,
    },
    usePagination
  );

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = tableInstance;

  useEffect(() => {
    setCurrentPageIndex(pageIndex);
  }, [pageIndex]);

  const handleInputChange = useCallback((e) => {
    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
    gotoPage(pageNumber);
  }, [gotoPage]);

  const exportFile = async () => {
    try {
      const response = await axios.get(`${API_URL}/export-excel`, {
        responseType: 'blob', // Treat response as binary data
      });

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Team_data.xlsx';
      link.click();

      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleFilterChange = useCallback((filterKey, selectedValues) => {
    setFilters(prev => ({
      ...prev,
      [filterKey]: selectedValues
    }));
    setCurrentPageIndex(0);
  }, []);

  return (
    <div className="table-container">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Team Entries</h4>
          <div
            style={{ height: "20px", width: "20px" }}
            onClick={() => {
              exportFile();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 128-168 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l168 0 0 112c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zM384 336l0-48 110.1 0-39-39c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39L384 336zm0-208l-128 0L256 0 384 128z" />
            </svg>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex gap-3 mb-3">
            <MultiSelectFilter 
              filterKey="category"
              handleFilterChange={handleFilterChange}
              selectedValues={filters.category || []}
              apiUrl={`${process.env.REACT_APP_BACKENE_BASE_URL}/admin/team/unique-category`}
            />
          </div>
          <div className="table-responsive">
            <table className="table dataTable display">
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th key={column.accessor}>{column.Header}</th>
                  ))}
                </tr>
              </thead>
              <tbody {...getTableBodyProps()}>
                {isLoading ? (
                  <tr>
                    <td colSpan={columns.length} className="text-center">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>
                ) : errorMessage ? (
                  <tr>
                    <td colSpan={columns.length} className="text-center">
                      <div className="alert alert-danger">{errorMessage}</div>
                    </td>
                  </tr>
                ) : processedData.length === 0 ? (
                  <tr>
                    <td colSpan={columns.length} className="text-center">
                      No data available
                    </td>
                  </tr>
                ) : (
                  page.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={`row-${rowIndex}`}>
                        {row.cells.map((cell, cellIndex) => (
                          <td {...cell.getCellProps()} key={`cell-${cellIndex}`}>
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          {!isLoading && !errorMessage && (
            <>
              <div className="d-flex justify-content-between">
                <span>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                  {' | '}
                  Total Teams: {totalTeams}
                </span>
                <span className="table-index">
                  Go to page:{' '}
                  <input
                    type="number"
                    className="form-control form-control-sm mx-2"
                    min={1}
                    max={pageOptions.length}
                    value={pageIndex + 1}
                    onChange={handleInputChange}
                    style={{ width: '60px', display: 'inline-block' }}
                  />
                </span>
              </div>
              <div className="text-center">
                <div className="filter-pagination mt-3">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {'<<'}
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Previous
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    Next
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => gotoPage(totalPages - 1)}
                    disabled={!canNextPage}
                  >
                    {'>>'}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamEntryTableBody;
