// src/components/DataTable.js

import React, { useMemo } from "react";
import TeamEntryTableBody from "./TeamEntries";
import IndividualEntryTableBody from "../Inputs/IndividualEntryTable";
import TableBody from "./formData";
import { TEAM_COLUMNS } from "../Inputs/teaminputs";
import { COLUMNS } from "../Inputs/competitionInputs";
import { INDIVIDUAL_COLUMNS } from "../Inputs/individualinputs";
const DataTable = ({ source }) => {
  let tableColumns = "";
  console.log(source);
  switch (source) {
    case "teamEntries":
      tableColumns = TEAM_COLUMNS;
      break;
    case "individualEntries":
      tableColumns = INDIVIDUAL_COLUMNS;
      break;
    default:
      tableColumns = COLUMNS;
      break;
  }
  function showTable() {
    switch (source) {
      case "teamEntries":
        return <TeamEntryTableBody columns={tableColumns} />;
      case "individualEntries":
        return <IndividualEntryTableBody columns={tableColumns}/>
      default:
        return <TableBody
          COLUMNS={tableColumns}
          allowedDocument={["logo_design_file", "aadhar_card_file", "sports_photography_files", "mascot_file"]}
          basePath={"https://creative-competitions.38nguk.in/"}
          DEFAULT_PAGE_SIZE={10}
          apiUrl={`${process.env.REACT_APP_BACKENE_BASE_URL}/competitionsform`}
          tableName={'Competitions Form'}
        />;
    }
  }
  return showTable();
};

export default DataTable;
