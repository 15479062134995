import React from 'react';
import { Route } from 'react-router-dom';
import tableConfig from "../config/tableConfig";
// Import admin-specific components
import Dashboard from '../jsx/components/Dashboard/Home';
import Home from '../jsx/components/Dashboard/Home';
import { getAllSubCategory, getAllUserCategory, getAllUserLevel } from '../services/superadminService/UserLevel';
import CommonTable from '../jsx/components/CommonTable/CommonTable';
import CategoryTypeList from '../admin/admincomponent/categoryType/CategoryTypeList';
import SubCategoryList from '../admin/admincomponent/subCategory/SubCategoryList';
import OrganizationList from '../admin/admincomponent/organization/OrganizationList';
import IndividualList from '../admin/admincomponent/Individual/IndividualList';
import IndividualVerificationForm from '../admin/admincomponent/Individual/IndividualVerificationForm';
import CompetitionTable from '../jsx/pages/table/TableFolder/table'
import ViewIndividual from '../jsx/pages/table/ViewParticipants/ViewIndividual';
import ViewTeam from '../jsx/pages/table/ViewParticipants/ViewTeam';


export const allAdminroutes = [
  /// Dashboard
  { url: "", component: <Home/> },
  { url: "dashboard", component: <Home /> },
  { url: "Organization", component: <OrganizationList/> },
  { url: "Individual", component: <IndividualList/> },
  { url: "Individual-verification-form", component: <IndividualVerificationForm/> },
  { url: "super-admin-client", component: <IndividualVerificationForm/> },
  { url: 'competition-table', component: <CompetitionTable source="competitionTable" /> },    
  { url: 'team-entries', component: <CompetitionTable source="teamEntries" /> },    
  { url: 'individual-entries', component: <CompetitionTable source="individualEntries" /> },    
  { url: 'individual-entries/:id', component: <ViewIndividual /> },    
  { url: 'team-entries/:id', component: <ViewTeam /> },    
  
  // { url: 'user-category', component: <CommonTable fetchData={getAllUserCategory} config={tableConfig['user-category']} /> },
  // { url: 'user-sub-category', component: <SubCategoryList  /> },
  // { url: 'user-category-type', component: <CategoryTypeList /> },
  

  

];