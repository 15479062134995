import React, { createContext, useState, useCallback } from "react";

// Create the context
export const AccompanistContext = createContext();

// Provider component
export const AccompanistProvider = ({ children }) => {
  const [accompanists, setAccompanists] = useState([]);

  // Function to update an individual accompanist's details
  const updateAccompanist = useCallback((index, updatedData) => {
    setAccompanists((prev) => {
      const newAccompanists = [...prev];
      newAccompanists[index] = { ...newAccompanists[index], ...updatedData };
      return newAccompanists;
    });
  }, []);

  // Function to initialize or reset the accompanists array
  const initializeAccompanists = useCallback((num) => {
    setAccompanists(Array.from({ length: num }, () => ({
      name: "",
      mobile: "",
      dob: "",
      document: null,
      photo: null,
      district: ""
    })));
  }, []);

  return (
    <AccompanistContext.Provider
      value={{
        accompanists,
        updateAccompanist,
        initializeAccompanists,
      }}
    >
      {children}
    </AccompanistContext.Provider>
  );
};
