import { GET_ORGANISATION_LIST, IMPORT_ADMIN_EXCEL } from "../../../config/adminUrlConfig";
import { ApiService, FileUploadService } from "../../../config/apiServices";

export const UploadAdminExcel = (data) => {
    return FileUploadService.post(IMPORT_ADMIN_EXCEL,data);
};


export const getOrganisationList = () => {
    return ApiService.get(GET_ORGANISATION_LIST);
};

