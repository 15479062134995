import React, { useContext } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { TeamMemberContext } from "./Team/TeamMemberContext";
import { AccompanistContext } from "./Individual/AccompanistContext";

const Districts = React.memo(
  ({ setFormData, districts, selectedDistrict, setSelectedDistricts, source }) => {
    // Call both contexts unconditionally
    const teamMemberContext = useContext(TeamMemberContext);
    const accompanistContext = useContext(AccompanistContext);

    return (
      <div>
        <DropdownButton
          as={ButtonGroup}
          id="dropdown-button-drop-down"
          drop="down"
          variant="primary"
          size="md"
          aria-required
          title={
            selectedDistrict && districts.length > 0
              ? districts.find((cat) => cat.city_id == selectedDistrict)?.city_name || "Select District"
              : "Select District"
          }
          className="me-1 mb-1"
          onSelect={(district) => {
            setSelectedDistricts(district);

            // Use the appropriate context based on `source`
            if (source?.name === "teammembers" && teamMemberContext) {
              teamMemberContext.updateTeamMember(source.index, { district });
            } else if (source?.name === "accompanists" && accompanistContext) {
              accompanistContext.updateAccompanist(source.index, { district });
            } else {
              setFormData((prev) => ({
                ...prev,
                district,
              }));
            }
          }}
        >
          {districts.length > 0 ? (
            districts.map((category) => (
              <Dropdown.Item key={category.id} eventKey={category.city_id}>
                {category.city_name}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item disabled>No Districts Available</Dropdown.Item>
          )}
        </DropdownButton>
      </div>
    );
  }
);

export default Districts;
