/// Menu
//import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useReducer, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";


//import { Dropdown } from "react-bootstrap";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { SuperAdminMenuList,AdminMenuList } from "./Menu";
import SuperAdminSidebar from "./SuperAdminSidebar";
import AdminSidebar from "./AdminSidebar";
import { AuthContext } from "../../../context/AuthContext";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active : "",
  activeSubmenu : "",
}

const SideBar = () => {
  const { user } = useContext(AuthContext);

	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
    ChangeIconSidebar,
	} = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);	
  
  let handleheartBlast = document.querySelector('.heart');
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)

 

  //let scrollPosition = useScrollPosition();
  /// Path
  
  /// Active menu

//   const filteredSuperAdminMenuList = SuperAdminMenuList.filter(item => {
//     console.log("item===>",item);
//     if (item.title === 'Client' && loginDetails.user.client_id > 0) {
//         return false; // Exclude Client menu if client_id is greater than 0
//     }
//     if (item.title === 'users Category' && loginDetails.user.client_id === 0) {
//         return false; // Exclude Users Category menu if client_id is 0
//     }
//     return true;
// })
// console.log(USERdATA,"USERSDaRTA")
    
  return (
    <div
        onMouseEnter={()=>ChangeIconSidebar(true)}
        onMouseLeave={()=>ChangeIconSidebar(false)}
        className={`dlabnav ${iconHover} ${
          sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
            ? hideOnScroll > 120
              ? "fixed"
              : ""
            : ""
        }`}
      //className="dlabnav"
      // className={`dlabnav ${iconHover} ${
      //   sidebarposition.value === "fixed" &&
      //   sidebarLayout.value === "horizontal" &&
      //   headerposition.value === "static"
      //     ? hideOnScroll > 120
      //       ? "fixed"
      //       : ""
      //     : ""
      // }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
			    <ul className="metismenu" id="menu">  
          {/* <SuperAdminSidebar state={state} setState={setState} menuListData={SuperAdminMenuList}/>   */}
          <AdminSidebar state={state} setState={setState} menuListData={SuperAdminMenuList}/> 

            {user?.role === 'sadmin' && 
             <SuperAdminSidebar state={state} setState={setState} menuListData={SuperAdminMenuList}/>  
            }   

               {user?.role === 'admin' && 
            <AdminSidebar state={state} setState={setState} menuListData={SuperAdminMenuList}/> 
            }         
            
                   
          </ul>		
        
          {/* 
          <div className="side-bar-profile">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="side-bar-profile-img me-2">
                <img src={user} alt="" />
              </div>
              <div className="profile-info1">
                <h4 className="fs-18 font-w500">Levi Siregar</h4>
                <span> leviregar@mail.com</span>
              </div>
              <div className="profile-button">
                <i className="fas fa-caret-down scale5 text-light"></i>
              </div>
            </div>	
            <div className="d-flex justify-content-between mb-2 progress-info">
              <span className="fs-12"><i className="fas fa-star text-orange me-2"></i>Task Progress</span>
              <span className="fs-12">20/45</span>
            </div>
            <div className="progress default-progress">
              <div className="progress-bar bg-gradientf progress-animated" style={{width: "45%", height:"10px"}} role="progressbar">
                <span className="sr-only">45% Complete</span>
              </div>
            </div>
          </div>
          <div className="copyright">
            <p><strong>Fillow Saas Admin</strong> © 2023 All Rights Reserved</p>
            <p className="fs-12">Made with <span className="heart" onClick={()=>heartBlast()}></span> by DexignLab</p>
          </div>
          */}
          
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
