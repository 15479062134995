import React from 'react';
import { Audio } from 'react-loader-spinner';

const Loader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Audio
        height="80"
        width="80"
        radius="9"
        color="green"
        ariaLabel="loading-indicator"
      />
    </div>
  );
}

export default Loader;
