export  const verifyFormData = [
    {
        id:1,
        feilds:"Full Name",
        Name:"Ravi ",
        REMARK:""
        
    },
    {
        id:2,
        feilds:"Father's Name",
        Name:"Ravi SHANKAR",
        REMARK:""
        
    },
    {
        id:3,
        feilds:"DOB",
        Name:"3 jan 1998",
        REMARK:""
        
    },
    {
        id:4,
        feilds:"Email",
        Name:"Ravi@gmail.com",
        REMARK:""
        
    },
    {
        id:5,
        feilds:"Address",
        Name:"asdfsdjfl",
        REMARK:""
        
    },
    {
        id:6,
        feilds:"Address",
        Name:"asdfsdjfl",
        REMARK:""
        
    },

    {
        id:7,
        feilds:"Sport",
        Name:"asdfsdjfl",
        REMARK:""
        
    },
    {
        id:8,
        feilds:"Club/Institute/Academy Name ",
        Name:"asdfsdjfl",
        REMARK:""
        
    },
    {
        id:9,
        feilds:"Athlete Level",
        Name:"asdfsdjfl",
        REMARK:""
        
    },
    {
        id:10,
        feilds:"Kit Size",
        Name:"asdfsdjfl",
        REMARK:""
        
    },


    {
        id:11,
        feilds:"Education Level",
        Name:"asdfs ",
        REMARK:""
        
    },
    {
        id:12,
        feilds:"Uploaded Document",
        Name:"Ravi SHANKAR",
        REMARK:""
        
    },
    {
        id:13,
        feilds:"Bank Name",
        Name:"xyz",
        REMARK:""
        
    },
    {
        id:14,
        feilds:"Account Holder Name ",
        Name:"xyc",
        REMARK:""
        
    },
    {
        id:15,
        feilds:"Account Number",
        Name:"asdfsdjfl",
        REMARK:""
        
    },
    {
        id:16,
        feilds:"IFSC Code",
        Name:"sdf",
        REMARK:""
        
    },

    {
        id:17,
        feilds:"Branch Name",
        Name:"asdfsdjfl",
        REMARK:""
        
    },
    {
        id:18,
        feilds:"Uploaded Document (Cheque/Passbook)",
        Name:"asdfsdjfl",
        REMARK:""
        
    },
 
]