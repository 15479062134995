import React, { useEffect, useState } from 'react'
import PageTitle from '../../../jsx/layouts/PageTitle';
import ReactPaginate from 'react-paginate';
import { Button } from 'react-bootstrap';
import { getAllSubCategory } from '../../../services/superadminService/SubCategory';
import { Link } from 'react-router-dom';
import { getOrganisationList } from '../../../services/adminApiService/organisationApi/OrganisationApiService';

const IndividualList = () => {
    const [organisationdataList, setOrganisationdataList] = useState([]);

    const getOrganisationListApi = async () => {

        try {

            const response = await getOrganisationList();
            setOrganisationdataList(response?.data)

        } catch (error) {
            console.error('Login error:', error);
        }
    }
    useEffect(() => {
        getOrganisationListApi();
    }, []);
    return (
        <>
            <PageTitle activeMenu="Table" motherMenu="Post" />
            <div className="col-12">

                <div className="card">
                    <div className="card-header d-flex">
                        <div>
                            <div className="input-group search-area">
                                <input type="text"
                                    className={`form-control `}
                                    placeholder="Search here..."
                                />
                                <span className="input-group-text" >
                                    <Link to={"#"}><i className="flaticon-381-search-2"></i></Link>
                                </span>
                            </div>
                        </div>
                        {/* <div>
                    <Button variant="primary" onClick={() => setShowAddModal(true)} className="mx-2">Add Sub Category  <i className="fa fa-plus" ></i></Button>
                </div> */}

                    </div>
                    <div className="card-body">
                        <div className="w-100 table-responsive">
                            <div id="example_wrapper" className="dataTables_wrapper">
                                <form >
                                    <table id="example" className="display w-100 dataTable">
                                        <thead>
                                            <tr>
                                                <th>Sr.No</th>
                                                <th>Prov.Reg.Id</th>
                                                <th>Applicant Full Name</th>
                                                <th> Email Id</th>
                                                <th>Contact Number</th>
                                                <th>Registration Date</th>
                                                <th>Attempt Number</th>
                                                <th>Status </th>
                                                <th>Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <>
                                                    <td>1</td>
                                                    <td>34</td>
                                                    <td>xzy</td>
                                                    <td>xyz@gamil.com</td>
                                                    <td>1234567890</td>
                                                    <td>2/3/2024</td>
                                                    <td>23</td>
                                                    <td><span className="text-success">Registered</span></td>

                                                    <td>
                                                        <div className="d-flex">
                                                            <Link to="Individual-verification-form">
                                                            <Button style={{ whiteSpace: 'nowrap' }}> Verify Application </Button>
                                                            </Link>
                                                           


                                                        </div>
                                                    </td>
                                                </>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>



                {/* <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageChange}
            pageRangeDisplayed={8}
            marginPagesDisplayed={7}
            pageCount={Math.ceil(clientData?.total / perPage)}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
        /> */}

            </div>


        </>
    )
}

export default IndividualList