export const CompetitionCategories = [
  {
    value: "folkDance",
    label: "Thematic-Innovation in Science and Technology",
  },
  { value: "folkSong", label: "Thematic-Science Mela" },
  {
    value: "classicalDance",
    label: "Cultural  Category-Group Folk Dance (10 members)",
  },
  {
    value: "classicalSinging",
    label: "Cultural  Category-Group Folk Song (10 members)",
  },
  {
    value: "classicalInstrumental",
    label: "Cultural  Category-One Act Play (10 members)",
  },
];


export const mediaRequiredCategories = [
    "classicalDance",
    "classicalSinging",
    "classicalInstrumental",
  ];