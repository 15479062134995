import React, { useContext, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';

import swal from "sweetalert";
import { Button, Modal } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import { CreateAllClientList, UploadClientLogo } from '../../../services/superadminService/ClientService';
import notify from '../../../utils/notification';
import { EyePasswordBtn } from '../../../common/ui/view/CustomEyePasswordBtn';
import { FileUploadService } from '../../../config/apiServices';

import { LocationContext } from '../../../context/LocationContext';
import { getAllUserCategory } from '../../../services/superadminService/UserLevel';
import { createSubCategory } from '../../../services/superadminService/SubCategory';
import { getAdminAllSubCategory } from '../../../services/adminApiService/adminSubCategory/AdminSubCategory';
import { UploadAdminExcel } from '../../../services/adminApiService/organisationApi/OrganisationApiService';
import { validateForm, validateSubCategorySelection } from './Validation';
import * as XLSX from 'xlsx';

const ImportExcelModal = ({ showImportModal, setShowImportModal, adminCategoryTypeData ,getOrganisationListApi}) => {
    const [file, setFile] = useState(null);
    const [errors, setErrors] = useState([]);
    const [excelErrors, setExcelErrors] = useState([]);
    const [addFormData, setAddFormData] = useState({
        user_category_main: '',
        category_type: '',
        sub_category_type: '',
        excelFile: '',
    });


    

    const [subCategorylistData, setSubCategorylistData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downLoadbtndisplay, setDownLoadbtndisplay] = useState(false);

    const handleAddFormChange = async (event) => {
        const { name, value } = event.target;

        setAddFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        if (name === "user_category_main") {
            setDownLoadbtndisplay(true);
        }

        if (name === "category_type") {
            await getAdminSubCategoryListApi(value);
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = '/SampleSheet.xlsx';
        link.download = 'SampleSheet.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const validateExcelData = (data) => {
        const errors = [];
        data.forEach((row, index) => {
            if (!row['Name of Legal Entity'] || row['Name of Legal Entity'].trim() === '') {
                errors.push(`Row ${index + 1}: Name of Legal Entity must not be empty.`);
            } else if (/[^a-zA-Z0-9 ]/.test(row['Name of Legal Entity'])) {
                errors.push(`Row ${index + 1}: Name of Legal Entity must not contain special characters.`);
            }
            if (!/^\d+$/.test(row['Institution Contact Number'])) {
                errors.push(`Row ${index + 1}: Institution Contact Number must be numeric.`);
            }
            if (!/\S+@\S+\.\S+/.test(row['Institution Email'])) {
                errors.push(`Row ${index + 1}: Institution Email is invalid.`);
            }
            if (!/^\d+$/.test(row['Contact Person Contact Number'])) {
                errors.push(`Row ${index + 1}: Contact Person Contact Number must be numeric.`);
            }
            if (!/\S+@\S+\.\S+/.test(row['Contact Person Email'])) {
                errors.push(`Row ${index + 1}: Contact Person Email is invalid.`);
            }
        });
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { user_category_main, category_type, sub_category_type } = addFormData;
        let formIsValid = true;
        const newErrors = {};

        if (!user_category_main) {
            newErrors.user_category_main = 'User Main Category is required.';
            formIsValid = false;
        }
        if (!category_type) {
            newErrors.category_type = 'Category Type is required.';
            formIsValid = false;
        }
        if (!sub_category_type) {
            newErrors.sub_category_type = 'Sub Category is required.';
            formIsValid = false;
        }
        if (!file) {
            newErrors.file = 'Please select an Excel file to upload.';
            formIsValid = false;
        }

        setErrors(newErrors);

        if (excelErrors.length > 0) {
            formIsValid = false;
        }

        if (!formIsValid) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('user_category_main', addFormData.user_category_main);
        formData.append('category_type', addFormData.category_type);
        formData.append('sub_category_type', addFormData.sub_category_type);

        try {
            const response = await UploadAdminExcel(formData);

            if (response?.status === 200) {

                if (response.data.failedRows && response.data.failedRows.length > 0) {
                    setErrors(response.data.failedRows.map(error => `Row ${error.row_number}: ${error.errors.join(', ')}`));
                    setShowImportModal(false);
                   
                    const errorHTML = `
                        <div class="alert alert-danger">
                            <h5>However, the following rows had issues:</h5>
                            ${response?.data?.failedRows?.map((error, index) => `
                                <div key=${index}>
                                    <strong>Row ${error?.row_number}:</strong>
                                    <ul>
                                        ${error?.errors?.map((err, idx) => `<li key=${idx}>${err}</li>`).join('')}
                                    </ul>
                                </div>
                            `).join('')}
                        </div>
                    `;
                
                    swal({
                        title: 'Upload Excel was successful!',
                        content: {
                            element: 'div',
                            attributes: {
                                innerHTML: errorHTML,
                            },
                        },
                        icon: 'success',
                    });
                } else {
                    setErrors([]);
                    swal('Success', 'Upload Excel was successful!', 'success');
                }
                getOrganisationListApi(); // Call the listing API to update the page
                setShowImportModal(false);
            } else {
                notify('error', response.data.message);
                setErrors([]);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                notify('error', error.response.data.message);
            } else {
                notify('error', 'An unexpected error occurred. Please try again.');
            }
            setErrors([]);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        const validFileTypes = [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ];
        if (!validFileTypes.includes(selectedFile.type)) {
            setErrors((prevErrors) => ({ ...prevErrors, file: 'Please upload a valid Excel file.' }));
            setFile(null);
            setExcelErrors([]); // Clear excelErrors when file type is invalid
            return;
        }
    
        setFile(selectedFile);
        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(sheet);
            const validationErrors = validateExcelData(data);
            if (validationErrors.length > 0) {
                setExcelErrors(validationErrors);
               // setErrors((prevErrors) => ({ ...prevErrors, file: validationErrors.join(', ') }));
               setErrors([]);
            } else {
                setExcelErrors([]);
                setErrors((prevErrors) => ({ ...prevErrors, file: '' }));
            }
        };
        reader.readAsBinaryString(selectedFile);
    };
    

    const DiscardHandler = () => {
        setShowImportModal(false);
        setDownLoadbtndisplay(false);
        setErrors([]);
        setExcelErrors([]);
    }

    const getAdminSubCategoryListApi = async (categoryTypeId) => {
        try {
            const response = await getAdminAllSubCategory(categoryTypeId);
            setSubCategorylistData(response?.data);
        } catch (error) {
            console.error('Login error:', error);
        }
    }

    useEffect(() => {
        if (showImportModal) {
            setErrors([]); // Reset errors when the modal is opened
            setExcelErrors([]); // Reset Excel errors when the modal is opened
        }
        getAdminSubCategoryListApi();
    }, [showImportModal]);

    return (
        <>
            <Modal className="modal fade" show={showImportModal} onHide={setShowImportModal}>
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">Import Excel</h4>
                                <button type="button" className="btn-close" onClick={DiscardHandler} data-dismiss="modal"><span></span></button>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <div className="row">
                                            <div className="form-group mb-3 col-md-6">
                                                <label className="text-black font-w500">User Main Category <span className="text-danger">*</span></label>
                                                <div className="contact-name">
                                                    <select
                                                        defaultValue={"option"}
                                                        name="user_category_main"
                                                        onChange={handleAddFormChange}
                                                        id="inputState"
                                                        className="form-control"
                                                    >
                                                        <option>Select Category Type</option>
                                                        <option value="2">Institute</option>
                                                    </select>
                                                    {errors.user_category_main && <span className="validation-text text-danger">{errors.user_category_main}</span>}
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 col-md-6">
                                                <label className="text-black font-w500">Select Category Type <span className="text-danger">*</span></label>
                                                <div className="contact-name">
                                                    <select
                                                        defaultValue={"option"}
                                                        name="category_type"
                                                        onChange={handleAddFormChange}
                                                        id="inputState"
                                                        className="form-control"
                                                    >
                                                        <option>Select Category Type</option>
                                                        {adminCategoryTypeData?.data?.map((item) => (
                                                            <option key={item?.id} value={item?.id}>{item?.category_name}</option>
                                                        ))}
                                                    </select>
                                                    {errors.category_type && <span className="validation-text text-danger">{errors.category_type}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group mb-3 col-md-6">
                                                <label className="text-black font-w500">Select Sub Category<span className="text-danger">*</span></label>
                                                <div className="contact-name">
                                                    <select
                                                        defaultValue={"option"}
                                                        name="sub_category_type"
                                                        onChange={handleAddFormChange}
                                                        id="inputState"
                                                        className="form-control"
                                                    >
                                                        <option>Select Sub Category</option>
                                                        {subCategorylistData?.map((item) => (
                                                            <option key={item?.id} value={item?.id}>{item?.category_name_view}</option>
                                                        ))}
                                                    </select>
                                                    {errors.sub_category_type && <span className="validation-text text-danger">{errors.sub_category_type}</span>}
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="formFile" className="form-label text-black font-w500">Upload Excel <span className="text-danger">*</span></label>
                                                <input className="form-control" type="file" name="excelFile" id="formFile" onChange={handleFileChange} />
                                                {errors.file && <span className="validation-text text-danger">{errors.file}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                {downLoadbtndisplay && (
                                    <div>
                                        <Button variant="primary" className="mx-2" onClick={handleDownload}>
                                            Download Excel
                                        </Button>
                                    </div>
                                )}
                                <div>
                                    <button type="submit" className="btn btn-primary mx-2" onClick={handleSubmit}>
                                        {loading ? 'Submit...' : 'Submit'}
                                    </button>
                                    <button type="button" onClick={DiscardHandler} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {excelErrors.length > 0 && (
                    <div className="alert alert-danger">
                        <h5>Validation Errors:</h5>
                        <ul>
                            {excelErrors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </Modal>
        </>
    );
}

export default ImportExcelModal;