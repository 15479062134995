import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { connect } from "react-redux";
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import axios from 'axios';
import NullCheckFilter from './NullCheckFilter.js'

const getFileExtension = (filename) => {
    return filename.split('.').pop().toLowerCase();
};

const isImageFile = (filename) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
    return imageExtensions.includes(getFileExtension(filename));
};

const getFileIcon = (filename) => {
    const extension = getFileExtension(filename);
    switch (extension) {
        case 'pdf':
            return 'ðŸ“„'; // You can replace these with actual icon components
        case 'doc':
        case 'docx':
            return 'ðŸ“';
        case 'xls':
        case 'xlsx':
            return 'ðŸ“Š';
        default:
            return '';
    }
};

function DataTable({
    tableName,
    DEFAULT_PAGE_SIZE = 10,
    apiUrl,
    basePath,
    allowedDocument,
    COLUMNS,
}) {
    // State Management
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [selectedNullChecks, setSelectedNullChecks] = useState([]);

    // Define filter fields based on your COLUMNS
    const filterFields = useMemo(() => {
        return COLUMNS
            .filter(column => column.filterable === true)
            .map(column => ({
                key: column.accessor,
                label: column.Header
            }));
    }, [COLUMNS]);

    // Define fetchTableData first
    const fetchTableData = useCallback(async (pageIndex) => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('access_token');
            
            const queryParams = {
                page: pageIndex + 1,
                limit: DEFAULT_PAGE_SIZE,
            };

            if (selectedNullChecks.length > 0) {
                queryParams.notNull = selectedNullChecks.join(',');
            }

            const response = await axios.get(apiUrl, {
                params: queryParams,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const { data, totalPages, totalItems, status } = response.data;

            if (!status) {
                throw new Error(response.data.message || 'Failed to fetch data');
            }

            setTableData(data);
            setTotalPages(totalPages);
            setTotalRecords(totalItems);
        } catch (error) {
            console.error('Error fetching data:', error); // Debug log
            setErrorMessage(error.message);
            setTableData([]);
        } finally {
            setIsLoading(false);
        }
    }, [DEFAULT_PAGE_SIZE, apiUrl, selectedNullChecks]);

    useEffect(() => {
        fetchTableData(currentPageIndex);
    }, [fetchTableData, currentPageIndex]);

    const tableColumns = useMemo(() => {
        return COLUMNS.map(column => ({
            ...column,
            Cell: column.Cell || (({ value }) => value)
        }));
    }, [COLUMNS]);
    const memoizedData = useMemo(() => tableData, [tableData]);

    const tableInstance = useTable(
        {
            columns: tableColumns,
            data: memoizedData,
            initialState: {
                pageIndex: 0,
                pageSize: DEFAULT_PAGE_SIZE
            },
            manualPagination: true,
            pageCount: totalPages,
            autoResetPage: false,
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );

    // Destructure table instance
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance;

    const { pageIndex } = state;

    // Event Handlers
    const handlePageChange = useCallback((pageNumber) => {
        setCurrentPageIndex(pageNumber);
    }, []);

    const handleInputChange = useCallback((e) => {
        const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
        setCurrentPageIndex(pageNumber);
    }, []);

    const exportFile = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKENE_BASE_URL}/competitionsform/export-excel`, {
                responseType: 'blob', // Treat response as binary data
            });

            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Competitions_data.xlsx';
            link.click();

            window.URL.revokeObjectURL(url); // Clean up
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
    // Render Methods
    const renderTableHeader = useCallback(() => (
        <thead>
            {headerGroups?.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                    {headerGroup.headers?.map(column => (
                        <th 
                            {...column.getHeaderProps()} 
                            key={column.id}
                            style={{
                                // Add specific width for tagline column
                                ...(column.id === 'tagline' && { 
                                    minWidth: '250px',
                                    maxWidth: '400px'
                                }),
                                // Default width for other columns
                                width: column.width || 'auto',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word'
                            }}
                        >
                            {column.render('Header')}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    ), [headerGroups]);

    const renderTableBody = useCallback(() => (
        <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
                prepareRow(row);
                row.values.serialNumber = (currentPageIndex * DEFAULT_PAGE_SIZE) + index + 1;
                console.log(row, "row data");
                return (
                    <tr {...row.getRowProps()} key={row.id}>
                        {row.cells.map(cell => {
                            return (
                                <td 
                                    {...cell.getCellProps()} 
                                    key={cell.column.id}
                                    style={{
                                        ...({
                                            minWidth: '250px',
                                            maxWidth: '400px',
                                            whiteSpace: 'normal',
                                            wordWrap: 'break-word',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        })
                                    }}
                                >
                                    {allowedDocument.includes(cell.column.id) ? (
                                        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                            {cell.value && typeof cell.value === 'string' ?
                                                cell.value.split(',').map((fileName, index) => {
                                                    const cleanFileName = fileName.trim();
                                                    const fileUrl = `${basePath}${cleanFileName}`;

                                                    return (
                                                        <div key={index} className="file-container">
                                                            <a
                                                                href={fileUrl}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'none',
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center',
                                                                    padding: '5px',
                                                                    border: '1px solid #ddd',
                                                                    borderRadius: '4px',
                                                                    width: '60px',
                                                                    height: '70px'
                                                                }}
                                                            >
                                                                {isImageFile(cleanFileName) ? (
                                                                    <img
                                                                        src={fileUrl}
                                                                        alt={`${cell.column.id}-${index + 1}`}
                                                                        style={{
                                                                            width: '50px',
                                                                            height: '50px',
                                                                            objectFit: 'cover',
                                                                            borderRadius: '4px',
                                                                            transition: 'transform 0.2s'
                                                                        }}
                                                                        onError={(e) => {
                                                                            if (!e.target.hasAttribute('data-error-logged')) {
                                                                                console.error('File failed to load:', {
                                                                                    url: fileUrl,
                                                                                    type: 'image'
                                                                                });
                                                                                e.target.setAttribute('data-error-logged', 'true');
                                                                            }
                                                                            e.target.src = '';
                                                                            e.target.onerror = null;
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <div style={{
                                                                            fontSize: '24px',
                                                                            marginBottom: '5px'
                                                                        }}>
                                                                            {getFileIcon(cleanFileName)}
                                                                        </div>
                                                                        <div style={{
                                                                            fontSize: '10px',
                                                                            textAlign: 'center',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                            width: '100%'
                                                                        }}>
                                                                            {getFileExtension(cleanFileName).toUpperCase()}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </a>
                                                        </div>
                                                    );
                                                })
                                                : (
                                                    <span>No files available</span>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        cell.render('Cell')
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    ), [
        DEFAULT_PAGE_SIZE,
        allowedDocument,
        basePath,
        currentPageIndex,
        getTableBodyProps,
        page,
        prepareRow
    ]);

    const renderPagination = useCallback(() => (
        <>
            <div className="d-flex justify-content-between">
                <span>
                    Page{' '}
                    <strong>
                        {currentPageIndex + 1} of {pageOptions.length}
                    </strong>
                    {' | '}
                    Total Records: {totalRecords}
                </span>
                <span className="table-index">
                    Go to page:{' '}
                    <input
                        type="number"
                        className="ml-2"
                        min={1}
                        max={pageOptions.length}
                        value={currentPageIndex + 1}
                        onChange={handleInputChange}
                    />
                </span>
            </div>
            <div className="text-center">
                <div className="filter-pagination mt-3">
                    <button
                        className="previous-button"
                        onClick={() => setCurrentPageIndex(0)}
                        disabled={!canPreviousPage || isLoading}
                    >
                        {'<<'}
                    </button>
                    <button
                        className="previous-button"
                        onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
                        disabled={!canPreviousPage || isLoading}
                    >
                        Previous
                    </button>
                    <button
                        className="next-button"
                        onClick={() => setCurrentPageIndex(currentPageIndex + 1)}
                        disabled={!canNextPage || isLoading}
                    >
                        Next
                    </button>
                    <button
                        className="next-button"
                        onClick={() => setCurrentPageIndex(totalPages - 1)}
                        disabled={!canNextPage || isLoading}
                    >
                        {'>>'}
                    </button>
                </div>
            </div>
        </>
    ), [
        currentPageIndex,
        pageOptions.length,
        totalRecords,
        totalPages,
        canPreviousPage,
        canNextPage,
        isLoading,
        handleInputChange
    ]);

    const handleNullCheckFilter = useCallback((selectedFields) => {
        setSelectedNullChecks(selectedFields);
        
        // Instead of building a where clause here, just update the selectedFilter
        if (selectedFields && selectedFields.length > 0) {
            // Join multiple fields with comma if needed
            const filterString = selectedFields.join(',');
            setSelectedFilter(filterString);
        } else {
            setSelectedFilter('');
        }
        
        // Reset to first page when filter changes
        setCurrentPageIndex(0);
    }, []);

    return (
        <div className="table-container">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{tableName}</h4>
                    <div
                        style={{ height: "20px", width: "20px" }}
                        onClick={() => {
                            exportFile();
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 128-168 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l168 0 0 112c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zM384 336l0-48 110.1 0-39-39c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39L384 336zm0-208l-128 0L256 0 384 128z" />
                        </svg>
                    </div>
                </div>
                <div className="card-body">
                    {errorMessage ? (
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    ) : (
                        <div>
                            <NullCheckFilter 
                                filterFields={filterFields}
                                handleFilterChange={handleNullCheckFilter}
                                selectedValues={selectedNullChecks}
                            />
                            <div className="table-responsive">
                                <table {...getTableProps()} className="table dataTable display">
                                    {renderTableHeader()}
                                    {isLoading ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={tableColumns.length} className="text-center">
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        renderTableBody()
                                    )}
                                </table>
                            </div>
                            {!isLoading && renderPagination()}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
});

export default connect(mapStateToProps)(DataTable);