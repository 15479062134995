import React, { Fragment, useState } from "react";
import { Button, ButtonGroup, SplitButton } from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import {
    Row,
    Col,
    Card,
    Table,
    Badge,
    Dropdown,
    ProgressBar,
} from "react-bootstrap";
import { verifyFormData } from "./VerifyForm";
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoMdInformationCircleOutline } from "react-icons/io";
import RemarkModal from "./RemarkModal";
import { FaUserLarge } from "react-icons/fa6";
import { RiInformation2Line } from "react-icons/ri";


const IndividualVerificationForm = () => {
    const [remarkshowModal, setRemarkshowModal] = useState(false);
    const [showremarkrCard, setshowremarkCard] = useState(true);

    const handleRadioChange = (event) => {
        if (event.target.className.includes('bg-danger')) {
            setRemarkshowModal(true);
            setshowremarkCard(false);
        }
    };
    return (
        <>
            <div className="row ">
                <Col lg={12} className="mb-3">
                    <Card style={{ height: 'auto' }}>
                        <div className="d-flex justify-content-between p-2">
                            <div className="mt-2">
                                <h4><span className="mx-2"><FaUserLarge className="text-primary" /></span><span className="mx-2">NAME OF STUDENT :RAVI SHANKAR</span></h4>
                            </div>
                            <div>
                                <div><strong>User Type</strong></div>
                                <Button className="bg-primary py-1">Athlete</Button>
                            </div>
                        </div>
                    </Card>




                </Col>

                <Col lg={12} className="mb-3">
                    <Card style={{ height: 'auto' }}>
                        <div className="d-flex justify-content-between p-2">
                            <div className="mt-2">
                                <h4><span className="mx-2 text-danger">Current Status:Form Verification is Ongoing</span></h4>
                            </div>

                        </div>
                    </Card>




                </Col>








            </div>
            <div className="row">
                <Col lg={12} className="mb-3">
                    <Card style={{ height: '35vh' }}>
                        <Card.Header>
                            <Card.Title className="text-danger">VERIFY PHOTO</Card.Title>
                        </Card.Header>
                        <Card.Body >
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Student Profile Photo</th>
                                        <th>Action</th>
                                        <th>Remarks</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>1</th>
                                        <th>Photo</th>
                                        <td>
                                            <div className="d-flex">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input bg-success"
                                                        type="radio"
                                                        name="gridRadios"
                                                        value="option1"
                                                        defaultChecked
                                                    />



                                                </div>
                                                <div className="form-check mx-2">
                                                    <input
                                                        className="form-check-input bg-danger"
                                                        type="radio"
                                                        name="gridRadios"
                                                        value="option1"
                                                        checked={false}
                                                        onChange={handleRadioChange}

                                                    />



                                                </div>
                                            </div>

                                        </td>
                                        <td>
                                            {/* <button onClick={() => setRemarkshowModal(true)}>remark</button> */}
                                        </td>

                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>


                    <Card className="mb-3" style={{ height: 'auto' }}>
                        <Card.Header>
                            <Card.Title className="text-danger">VERIFY FORM FIELDS & DOCUMENTS</Card.Title>
                        </Card.Header>
                        <Card.Body >
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Form Fields</th>
                                        <th>Action</th>
                                        <th>Remarks</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {verifyFormData.map((data) => {
                                        return (
                                            <>
                                                <tr>
                                                    <th>{data.id}</th>
                                                    <th>
                                                        <div className="text-primary">{data.feilds}</div>
                                                        <div>{data.Name}</div>
                                                    </th>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input bg-success"
                                                                    type="radio"
                                                                    name="gridRadios"
                                                                    value="option1"
                                                                    defaultChecked
                                                                />



                                                            </div>
                                                            <div className="form-check mx-2">
                                                                <input
                                                                    className="form-check-input bg-danger"
                                                                    type="radio"
                                                                    name="gridRadios"
                                                                    value="option1"
                                                                    defaultChecked
                                                                    onClick={() => setRemarkshowModal(true)}
                                                                />



                                                            </div>
                                                        </div>

                                                    </td>
                                                    <td>
                                                        this worng photo
                                                    </td>

                                                </tr>
                                            </>
                                        )
                                    })}

                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                </Col>








            </div>
            {showremarkrCard ?
             <div className="row ">
             <Col lg={12} className="mb-3">
                 <Card style={{ height: 'auto' }}>
                     <Card.Header>
                         <Card.Title> <IoCheckmarkOutline className="text-success" /> CASE:VERIFIED</Card.Title>
                     </Card.Header>
                     <Card.Body >
                         <div className="form-check d-flex justify-content-center">
                             <div>
                                 <input className="form-check-input" type="checkbox" />
                                 <label className="form-check-label">
                                     I have checked,profile photo,form fields and documents provided in "Certify Application Form " section.(click on Submit button,if data is Certified)
                                 </label>
                             </div>
                         </div>

                         <div className=" d-flex justify-content-end mt-3">
                             <div>
                                 <Button>
                                     Certify & Submit
                                 </Button>
                                 <strong>
                                     <p> <IoMdInformationCircleOutline className="text-warning" /> Form will be Sent to Principal</p>
                                 </strong>

                             </div>

                         </div>
                     </Card.Body>
                 </Card>




             </Col>








         </div>
         :
         <div className="row ">
                <Col lg={12} className="mb-3">
                    <Card style={{ height: 'auto' }}>
                        <Card.Header>
                            <Card.Title className="w-100"> 
                            <div className="d-flex justify-content-between w-100 ">
                                <div>
                               <span><RiInformation2Line className="text-danger"/></span> CASE:NOT CERTIFIED
                                </div>
                                <div className="bg-danger text-white">
                                    <h4>incorrect Fields:1</h4>
                                </div>
                            </div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body >
                            <div className="form-check d-flex justify-content-start">
                                <div>
                                    <input className="form-check-input" type="checkbox" />
                                    <label className="form-check-label">
                                        I have checked,profile photo,form fields and documents provided in "Certify Application Form " section.(Choose one of the below option to complete you process).
                                    </label>
                                </div>
                            </div>

                            <div className=" d-flex  mt-3">
                            <div className="form-check">
                                        <input
                                            className="form-check-input bg-danger"
                                            type="radio"
                                            name="gridRadios"
                                            value="option1"
                                            defaultChecked

                                        />
                                        <label className="form-check-label">
                                            Not Meeting Criteria 
                                        </label>



                                    </div>
                                    <div className="form-check mx-3">
                                        <input
                                            className="form-check-input bg-warning"
                                            type="radio"
                                            name="gridRadios"
                                            value="option1"
                                            defaultChecked

                                        />
                                        <label className="form-check-label">
                                           Form Incomplete
                                        </label>



                                    </div>

                            </div>
                        </Card.Body>
                    </Card>




                </Col>








            </div>
            }
           


            
            <RemarkModal remarkshowModal={remarkshowModal} setRemarkshowModal={setRemarkshowModal} />
        </>

    )
}

export default IndividualVerificationForm