export const BASE_URL = process.env.REACT_APP_BACKENE_BASE_URL || "http://127.0.0.1:8000";

//auth url
export const LOGIN_URL = "/login/admin";



// User url
export const GET_USER_URL = "/api/admin/getUserLevel";


//  CATEGORIES TYPE
export const GET_ADMIN_CATEGORY_TYPE_URL = "/api/admin/findUserCategoryType";


// SUB CATEGORIES URL
export const GET_ADMIN_SUB_CATEGORY_URL = "/api/admin/findUserCategorySubTypeByUserCategoryTypeId/";


// REGISTRATION EXCEL IMPORT
export const IMPORT_ADMIN_EXCEL = "/api/admin/importRegistration";
export const GET_ORGANISATION_LIST = "/api/admin/getRegistrationOrganizationData";