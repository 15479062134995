const MOCK_DATA = [
    {
        "id": 14,
        "name": "HARSHIT KUNIYAL ",
        "school": "JAI PUBLIC SCHOOL (GURUKUL BARADA), AMBALA(HR) ",
        "dob": "2009-09-26",
        "father_name": "RAMESH CHANDRA KUNIYAL ",
        "mother_name": "ANJANA KUNIYAL",
        "email": "rameshkuniyal7@gmail.com",
        "contact": "7895571002",
        "tagline": "\"I'm Harshit Kuniyal, a 10th-grader at Gurukul. I excel in wrestling and weightlifting",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "",
        "logo_design_file": null,
        "aadhar_number": "635909277281",
        "aadhar_card_file": "uploads/har pdf.pdf"
    },
    {
        "id": 15,
        "name": "Aditi Saini",
        "school": "Jamdagni public school",
        "dob": "2010-10-21",
        "father_name": "Arun kumar",
        "mother_name": "Monika saini",
        "email": "www.mohansainisadhyan50@gmail.com",
        "contact": "7500274221",
        "tagline": "No child left behind",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "",
        "logo_design_file": null,
        "aadhar_number": "475545676554",
        "aadhar_card_file": "uploads/Laksar.pdf"
    },
    {
        "id": 16,
        "name": "Kunal  Saini ",
        "school": "Jamdagni public school ",
        "dob": "2013-08-31",
        "father_name": "Vikas Kumar ",
        "mother_name": "Vishakha Saini ",
        "email": "vishakhasaini@gmail.com",
        "contact": "9027044119",
        "tagline": "",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "",
        "logo_design_file": null,
        "aadhar_number": "278175801965",
        "aadhar_card_file": null
    },
    {
        "id": 17,
        "name": "Ishu panwar",
        "school": "Jamdagni public school ",
        "dob": "2010-03-27",
        "father_name": "Mr. Pankaj Gujjar ",
        "mother_name": "Mrs. Babita ",
        "email": "pankajgujjar937@gmail.com",
        "contact": "7252936500",
        "tagline": "",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "",
        "logo_design_file": "uploads/IMG_20241030_062209.jpg",
        "aadhar_number": "535775852889",
        "aadhar_card_file": "uploads/IMG_20241030_062209.jpg"
    },
    {
        "id": 18,
        "name": "Safiha",
        "school": "J.P.S.",
        "dob": "2009-01-17",
        "father_name": "Mr.Anees ahmed",
        "mother_name": "Mrs.Shabnam",
        "email": "lepuaadd260@gmail.com",
        "contact": "9897410463",
        "tagline": "",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "",
        "logo_design_file": "uploads/Screenshot_2024-10-31-10-41-47-24_680d03679600f7af0b4c700c6b270fe7.jpg",
        "aadhar_number": "830582072304",
        "aadhar_card_file": null
    },
    {
        "id": 19,
        "name": "Rohan Sharma",
        "school": "Modern School, New Delhi",
        "dob": "2008-05-14",
        "father_name": "Rajat Sharma",
        "mother_name": "Pooja Sharma",
        "email": "rohan.sharma@mail.com",
        "contact": "9876543210",
        "tagline": "Achieve to Inspire",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "#RohanInAction",
        "logo_design_file": null,
        "aadhar_number": "123456789012",
        "aadhar_card_file": "uploads/rohan_aadhar.pdf"
    },
    {
        "id": 20,
        "name": "Simran Kaur",
        "school": "Springfield High, Ludhiana",
        "dob": "2009-11-12",
        "father_name": "Gurmeet Kaur",
        "mother_name": "Amrit Kaur",
        "email": "simran.kaur@mail.com",
        "contact": "9123456789",
        "tagline": "Rise and Shine",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "#SimranSpirit",
        "logo_design_file": null,
        "aadhar_number": "234567890123",
        "aadhar_card_file": "uploads/simran_aadhar.pdf"
    },
    {
        "id": 21,
        "name": "Aman Verma",
        "school": "St. John's High School, Jaipur",
        "dob": "2007-03-23",
        "father_name": "Manoj Verma",
        "mother_name": "Sonal Verma",
        "email": "aman.verma@mail.com",
        "contact": "9234567890",
        "tagline": "Dream Big, Achieve Bigger",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "#AmanDreams",
        "logo_design_file": null,
        "aadhar_number": "345678901234",
        "aadhar_card_file": "uploads/aman_aadhar.pdf"
    },
    {
        "id": 22,
        "name": "Nisha Singh",
        "school": "Green Valley School, Patna",
        "dob": "2010-08-15",
        "father_name": "Suraj Singh",
        "mother_name": "Neha Singh",
        "email": "nisha.singh@mail.com",
        "contact": "9345678901",
        "tagline": "Success is a Journey",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "#NishaSuccess",
        "logo_design_file": null,
        "aadhar_number": "456789012345",
        "aadhar_card_file": "uploads/nisha_aadhar.pdf"
    },
    {
        "id": 23,
        "name": "Rajesh Patel",
        "school": "Oxford Public School, Surat",
        "dob": "2008-10-05",
        "father_name": "Nitin Patel",
        "mother_name": "Kavita Patel",
        "email": "rajesh.patel@mail.com",
        "contact": "9456789012",
        "tagline": "Keep Moving Forward",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "#RajeshMoves",
        "logo_design_file": null,
        "aadhar_number": "567890123456",
        "aadhar_card_file": "uploads/rajesh_aadhar.pdf"
    },
    {
        "id": 24,
        "name": "Priya Desai",
        "school": "The Heritage School, Pune",
        "dob": "2007-02-19",
        "father_name": "Mahesh Desai",
        "mother_name": "Anjali Desai",
        "email": "priya.desai@mail.com",
        "contact": "9567890123",
        "tagline": "Believe, Act, Succeed",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "#PriyaBelieves",
        "logo_design_file": null,
        "aadhar_number": "678901234567",
        "aadhar_card_file": "uploads/priya_aadhar.pdf"
    },
    {
        "id": 25,
        "name": "Kiran Mehta",
        "school": "Silver Oaks, Bengaluru",
        "dob": "2009-06-30",
        "father_name": "Ajay Mehta",
        "mother_name": "Sakshi Mehta",
        "email": "kiran.mehta@mail.com",
        "contact": "9678901234",
        "tagline": "Explore to Grow",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "#KiranGrows",
        "logo_design_file": null,
        "aadhar_number": "789012345678",
        "aadhar_card_file": "uploads/kiran_aadhar.pdf"
    },
    {
        "id": 26,
        "name": "Vikram Chawla",
        "school": "Vidya Mandir, Ahmedabad",
        "dob": "2008-01-25",
        "father_name": "Ramesh Chawla",
        "mother_name": "Seema Chawla",
        "email": "vikram.chawla@mail.com",
        "contact": "9789012345",
        "tagline": "Stay Curious, Stay Smart",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "#VikramSmart",
        "logo_design_file": null,
        "aadhar_number": "890123456789",
        "aadhar_card_file": "uploads/vikram_aadhar.pdf"
    },
    {
        "id": 27,
        "name": "Sonal Gupta",
        "school": "Loyola High School, Mumbai",
        "dob": "2010-04-10",
        "father_name": "Ashok Gupta",
        "mother_name": "Ritu Gupta",
        "email": "sonal.gupta@mail.com",
        "contact": "9890123456",
        "tagline": "Create Your Own Path",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "#SonalPath",
        "logo_design_file": null,
        "aadhar_number": "901234567890",
        "aadhar_card_file": "uploads/sonal_aadhar.pdf"
    },
    {
        "id": 28,
        "name": "Ankit Joshi",
        "school": "Bright Future School, Indore",
        "dob": "2009-12-01",
        "father_name": "Amit Joshi",
        "mother_name": "Sunita Joshi",
        "email": "ankit.joshi@mail.com",
        "contact": "9901234567",
        "tagline": "Innovate and Inspire",
        "mascot_file": null,
        "theme_song": "",
        "sports_photography_files": null,
        "hashtag": "#AnkitInnovates",
        "logo_design_file": null,
        "aadhar_number": "012345678901",
        "aadhar_card_file": "uploads/ankit_aadhar.pdf"
    }

]
const COLUMNS = [
    { Header: "Sr No.", footer: "Sr No.", accessor: "serialNumber" },
    // { Header: "Id", footer: "Id", accessor: "id" },
    { Header: "Name", footer: "Name", accessor: "name" },
    { Header: "School", footer: "School", accessor: "school" },
    { Header: "DOB", footer: "DOB", accessor: "dob" },
    { Header: "Father Name", footer: "Father Name", accessor: "father_name" },
    { Header: "Mother Name", footer: "Mother Name", accessor: "mother_name" },
    { Header: "Email", footer: "Email", accessor: "email" },
    { Header: "Contact", footer: "Contact", accessor: "contact" },
    { Header: "Tagline", footer: "Tagline", accessor: "tagline", filterable: true },
    { Header: "Mascot File", footer: "Mascot File", accessor: "mascot_file", filterable: true },
    { Header: "Theme Song", footer: "Theme Song", accessor: "theme_song", filterable: true },
    { Header: "Sports Photography Files", footer: "Sports Photography Files", accessor: "sports_photography_files",filterable: true },
    { Header: "Hashtag", footer: "Hashtag", accessor: "hashtag", filterable: true },
    { Header: "Logo Design File", footer: "Logo Design File", accessor: "logo_design_file", filterable: true },
    { Header: "Aadhar Number", footer: "Aadhar Number", accessor: "aadhar_number" },
    { Header: "Aadhar Card File", footer: "Aadhar Card File", accessor: "aadhar_card_file" }
]

export {
    COLUMNS,
    MOCK_DATA
}