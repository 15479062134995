import { useNavigate } from "react-router-dom";

const MOCK_DATA = [
    {
        "id": 1,
        "name": "dsfs",
        "isLeader": false,
        "category": "folkSong",
        "teamName": "dfds"
    },
    {
        "id": 2,
        "name": "dflsjl",
        "isLeader": true,
        "category": "folkSong",
        "teamName": "dfds"
    }
];

const TEAM_COLUMNS = [
    { 
        id: 'serialNumber',
        Header: "Sr No.", 
        footer: "Sr No.", 
        accessor: "serialNumber" 
    },
    { 
        id: 'leader',
        Header: "Leader", 
        footer: "Leader", 
        accessor: "leader" 
    },
    { 
        id: 'category',
        Header: "Category", 
        footer: "Category", 
        accessor: "category" 
    },
    { 
        id: 'teamName',
        Header: "Team Name", 
        footer: "Team Name", 
        accessor: "teamName" 
    },
    { 
        id: 'members',
        Header: "Team Members", 
        footer: "Members", 
        accessor: "members" 
    },
    { 
        id: 'email',
        Header: "Email", 
        footer: "Email", 
        accessor: "email"
    },
    { 
        id: 'mobileNumber',
        Header: "Mobile", 
        footer: "mobile", 
        accessor: "mobileNumber"
    },
    { 
        id: 'dob',
        Header: "Date of Birth", 
        footer: "Date of Birth", 
        accessor: "dob"
    },
    { 
        id: 'district',
        Header: "District", 
        footer: "District", 
        accessor: "district"
    },
    {
        Header: "Detail",
        footer: "Detail",
        accessor: "id",
        Cell: ({ row }) => {
          const navigate = useNavigate();
          const id = row.original.id; // Access the ID of the current row
          return (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => navigate(`${id}`)}
            >
              View
            </button>
          );
        },
      }
];


export {
    TEAM_COLUMNS,
    MOCK_DATA
}
