import React, { useContext, useEffect, useState } from "react";
// import { AccompanistContext } from "./AccompanistContext";
// import Accompanists from "./Accompanists";
import AdditionalOptions from "./TeamOptions";
import { TeamMemberContext } from "./TeamMemberContext";
import TeamMembers from "./TeamMembers";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axiosInstance from "../../../../services/AxiosInstance";
import { validateAge } from "../helper";
import Loader from "../Loader";
import Districts from "../Districts";
import { mediaRequiredCategories } from "./Categories";

const Team = () => {
  const { teamMember, initializeTeamMember } = useContext(TeamMemberContext);
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDistrict, setSelectedDistricts] = useState("");
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    leaderName: "",
    fatherName: "",
    dob: "",
    mobileNumber: "",
    email: "",
    photo: null,
    address: "",
    district: "",
    certificate: null,
    category: "",
    teamName: "",
    media: null,
    teamMembers: [],
  });
  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  useEffect(() => {
    initializeTeamMember(0);
    const getAllDistricts = async () => {
      await axiosInstance.get("/districts").then((res) => {
        setDistricts(res.data);
      });
    };
    getAllDistricts();
  }, [initializeTeamMember]);
  function handleFileChange(e, name) {
    const file = e.target.files[0];

    if (file) {
      let validTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (name == "photo") {
        validTypes = ["image/jpeg", "image/png"];
      }
      const maxSize = 1 * 1024 * 1024; // 1MB in bytes

      if (!validTypes.includes(file.type)) {
        // alert("Only JPG, PNG, and PDF files are allowed.");
        Swal.fire({
          title: "<strong>Invalid File Type</strong>",
          icon: "info",
          html: `
            File Type must be JPG${
              name === "certificate" ? ", PDF, or" : " or"
            } PNG.
             `,
          showCloseButton: true,
        });
        e.target.value = ""; // Clear the selected file
        return;
      }

      if (file.size > maxSize) {
        // alert("File size must be less than or equal to 1MB.");
        Swal.fire({
          title: "<strong>File Size Exceeds</strong>",
          icon: "info",
          html: `
            File size must be less than or equal to 1MB
          `,
          showCloseButton: true,
        });
        e.target.value = "";
        return;
      }
      if (name == "certificate") {
        setFormData((prev) => ({
          ...prev,
          certificate: file,
        }));
      }
      if (name == "photo") {
        setFormData((prev) => ({
          ...prev,
          photo: file,
        }));
      }
    }
  }

  function handleMediaChange(e, name) {
    const file = e.target.files[0];

    if (file) {
      // Define valid file types for images, audio, and video
      const validTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "video/mp4",
        "video/avi",
        "video/mkv",
        "video/mov",
        "video/wmv",
        "video/flv",
        "video/webm",
        "video/m4v",
        "video/3gp",
        "audio/mp3",
        "audio/wav",
        "audio/ogg",
        "audio/aac",
      ];

      const maxSize = 100 * 1024 * 1024; // 100MB in bytes

      // Check if the file type is valid
      if (!validTypes.includes(file.type)) {
        Swal.fire({
          title: "<strong>Invalid File Type</strong>",
          icon: "info",
          html: `The file type must be one of the following: JPG, PNG, GIF, MP4, AVI, MKV, MOV, WMV, FLV, WEBM, M4V, 3GP, MP3, WAV, OGG, AAC.`,
          showCloseButton: true,
        });
        e.target.value = ""; // Clear the selected file
        return;
      }

      // Check if the file size exceeds the limit
      if (file.size > maxSize) {
        Swal.fire({
          title: "<strong>File Size Exceeds</strong>",
          icon: "info",
          html: `File size must be less than 100MB.`,
          showCloseButton: true,
        });
        e.target.value = ""; // Clear the selected file
        return;
      }

      // Check if the file is an image (no duration check needed)
      if (file.type.startsWith("image/")) {
        // Set the file directly in state if it's an image
        setFormData((prev) => ({
          ...prev,
          [name]: file,
        }));
        return; // Exit the function as no further checks are required for images
      }

      // Check the duration of audio and video files
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        const blob = new Blob([event.target.result]);
        const url = URL.createObjectURL(blob);
        let mediaElement;

        // Determine if it's an audio or video file
        if (file.type.startsWith("audio/")) {
          mediaElement = new Audio(url);
        } else if (file.type.startsWith("video/")) {
          mediaElement = document.createElement("video");
          mediaElement.src = url;
        }

        mediaElement.onloadedmetadata = () => {
          const duration = mediaElement.duration; // Get duration in seconds
          if (duration > 60) {
            Swal.fire({
              title: "<strong>Duration Exceeds</strong>",
              icon: "info",
              html: `The length of the audio or video must be less than 1 minute.`,
              showCloseButton: true,
            });
            e.target.value = ""; // Clear the selected file
            return;
          }

          // If all validations pass, set the file in state
          setFormData((prev) => ({
            ...prev,
            [name]: file, // Dynamically set the appropriate field in state
          }));
        };

        // Cleanup the URL object
        mediaElement.onended = () => {
          URL.revokeObjectURL(url);
        };
        mediaElement.load(); // Load the media to get metadata
      };
      fileReader.readAsArrayBuffer(file);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCategory) {
      setError("Category selection is required.");
      Swal.fire({
        title: "Error",
        icon: "error",
        html: error,
        showCloseButton: true,
      });
      return;
    }

    if (!formData.district) {
      Swal.fire({
        title: "District is Required",
        text: "Select District from Dropdown",
        icon: "error",
      });
      return;
    }

    if (!validateAge(formData.dob)) {
      Swal.fire({
        title: "Invalid Age?",
        text: "Age must be between 15 to 29 years",
        icon: "question",
      });
      return; // Stop further processing if age is not in the valid range
      // }
    }
    if (mediaRequiredCategories.includes(selectedCategory) && !formData.media) {
      Swal.fire({
        title: "Upload Media",
        text: "Media is required for the mentioned category.",
        icon: "error",
      });
      return;
    }
    for (const member of teamMember) {
      if (member.dob && !validateAge(member.dob)) {
        Swal.fire({
          title: "Invalid Team Member Age!",
          text: "All team members must be between 15 to 29 years old.",
          icon: "error",
        });
        return;
      }
      if (!member.district) {
        Swal.fire({
          title: "District is Required",
          text: "Select District from Dropdown",
          icon: "error",
        });
        return;
      }
    }
    if (!formData.district) {
      setError("District selection is required.");
      Swal.fire({
        title: "Error",
        icon: "error",
        html: error,
        showCloseButton: true,
      });
      return;
    }

    const formDataToSubmit = new FormData();
    formDataToSubmit.append("leaderName", formData.name);
    formDataToSubmit.append("fatherName", formData.fatherName);
    formDataToSubmit.append("dob", formData.dob);
    formDataToSubmit.append("mobileNumber", formData.mobile);
    formDataToSubmit.append("email", formData.email);
    formDataToSubmit.append("address", formData.address);
    formDataToSubmit.append("certificate", formData.certificate);
    formDataToSubmit.append("teamName", formData.teamName);
    formDataToSubmit.append("district", formData.district);
    formDataToSubmit.append("category", selectedCategory);
    formDataToSubmit.append("media", formData.media);
    formDataToSubmit.append("photo", formData.photo);
    console.log(teamMember);
    if (teamMember.length > 0) {
      let ind = 0;
      teamMember.forEach((member) => {
        // Only include members with valid data
        if (member.name && member.mobile) {
          formDataToSubmit.append(`teamMembers[${ind}][name]`, member.name);
          formDataToSubmit.append(`teamMembers[${ind}][mobile]`, member.mobile);
          formDataToSubmit.append(
            `teamMembers[${ind}][district]`,
            member.district
          );
          formDataToSubmit.append(`teamMembers[${ind}][dob]`, member.dob);
          formDataToSubmit.append(
            `teamMembers[${ind}][document]`,
            member.document
          );
          formDataToSubmit.append(`teamMembers[${ind}][photo]`, member.photo);
          ind++;
        }
      });
    }

    console.log("Form Data:", formDataToSubmit);

    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "/submit-team",
        formDataToSubmit,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Specify that you're sending FormData
          },
        }
      );
      console.log(response);
      Swal.fire({
        title: "Success!",
        text: "Application Filled Successfully!",
        icon: "success",
      });

      setError(""); // Clear error on successful submission
      navigate("/page-creative");
    } catch (error) {
      // Handle error response
      console.error("Error submitting form data:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.errors.message,
      });
    } finally {
      setLoading(false); // Step 3: Set loading to false after API call completes
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-center align-items-center mb-2">
            <h3>Team Registration Form</h3>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="mb-4 d-flex justify-content-between align-items-center">
              <h4>
                <label>Team Leader Details </label>
              </h4>
              <span className="text-red">* marked fields are required </span>
            </div>

            <div className="row justify-content-between">
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      Name<span className="text-red">*</span>
                    </strong>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    required
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      Father's Name<span className="text-red">*</span>
                    </strong>
                  </label>
                  <input
                    type="text"
                    required
                    name="fatherName"
                    className="form-control"
                    placeholder="Father's Name"
                    value={formData.fatherName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      Date of Birth<span className="text-red">*</span>
                    </strong>
                  </label>
                  <input
                    type="date"
                    required
                    name="dob"
                    className="form-control"
                    value={formData.dob}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      Mobile Number<span className="text-red">*</span>
                    </strong>
                  </label>
                  <input
                    type="number"
                    name="mobile"
                    required
                    className="form-control"
                    placeholder="Mobile Number"
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3 ">
                  <div className="d-flex justify-content-between">
                    <label className="mb-1">
                      <strong>
                        Upload Photo<span className="text-red">*</span>
                      </strong>
                    </label>
                    <label className="">.jpg, .jpeg, .png, .pdf (≤ 1 MB)</label>
                  </div>
                  <input
                    type="file"
                    placeholder="Certificate"
                    className="form-control"
                    required
                    accept=".jpg,.jpeg,.png,.pdf"
                    onChange={(e) => handleFileChange(e, "photo")}
                  />
                </div>
              </div>
            </div>

            <div className="row justify-content-between">
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Email</strong>
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="form-control"
                    // required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3 ">
                  <div className="d-flex justify-content-between">
                    <label className="mb-1">
                      <strong>
                        Upload Media of Competition
                        <span className="text-red">*</span>
                      </strong>
                    </label>
                    <label className="">
                      Upload Audio, Video or Image (≤ 100 MB)
                    </label>
                  </div>
                  <input
                    type="file"
                    placeholder="Select a File"
                    className="form-control"
                    required
                    accept=".jpg,.jpeg,.png,.gif,.mp4,.avi,.m4v,.mkv,.mov,.wmv,.flv,.webm,.m4v,.3gp,.mp3,.wav,.ogg,.aac"
                    onChange={(e) => handleMediaChange(e, "media")}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-8">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      Complete Address<span className="text-red">*</span>
                    </strong>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    required
                    className="form-control"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      District<span className="text-red">*</span>
                    </strong>
                  </label>
                  {districts && (
                    <Districts
                      setFormData={setFormData}
                      districts={districts}
                      selectedDistrict={selectedDistrict}
                      setSelectedDistricts={setSelectedDistricts}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-12">
                <div className="form-group mb-3 ">
                  <div className="d-flex justify-content-between">
                    <label className="mb-1">
                      <strong>
                        Upload Certificate (Birth Certificate/High School
                        Certificate School/College/University Score Card ID
                        Proof)<span className="text-red">*</span>
                      </strong>
                    </label>
                    <label className="">.jpg, .jpeg, .png, .pdf (≤ 1 MB)</label>
                  </div>
                  <input
                    type="file"
                    placeholder="Certificate"
                    className="form-control"
                    required
                    accept=".jpg,.jpeg,.png,.pdf"
                    onChange={(e) => handleFileChange(e, "certificate")}
                  />
                </div>
              </div>
            </div>

            <div className="mb-4 mt-4">
              <h4>
                <label>Team Details </label>
              </h4>
            </div>

            <div className="row justify-content-between">
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      Team Name<span className="text-red">*</span>
                    </strong>
                  </label>
                  <input
                    type="text"
                    name="teamName"
                    placeholder="Team Name"
                    required
                    className="form-control"
                    value={formData.teamName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>
                      Select Category<span className="text-red">*</span>
                    </strong>
                  </label>
                  <AdditionalOptions
                    setFormData={setFormData}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                  />
                </div>
              </div>
            </div>

            <TeamMembers districts={districts} />

            <div className="d-flex justify-content-center mt-4">
              <button type="submit" className="btn btn-primary btn-block">
                Submit
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default Team;
export { validateAge };
