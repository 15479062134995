import React, { useContext } from "react";
import { AccompanistContext } from "./AccompanistContext";
import AccompanistDetail from "./AccompanistDetail";

const Accompanists = ({districts}) => {
  const { accompanists, initializeAccompanists } =
    useContext(AccompanistContext);

  const handleNumOfAccompanistsChange = (e) => {
    const num = Math.min(4, Math.max(0, parseInt(e.target.value, 10) || 0));
    initializeAccompanists(num);
  };

  return (
    <div className="form-group mt-5">
      <div className="d-flex justify-content-between align-items-center">
        <label>
          <strong>Enter Accompanists:</strong>
        </label>
        <strong><i>Max 4</i></strong>
      </div>
      <input
        type="number"
        className="form-control"
        max={4}
        required
        placeholder="Enter number of Accompanists*"
        onWheel={(e) => e.target.blur()}
        onChange={handleNumOfAccompanistsChange}
        value={accompanists.length}
      />

      {accompanists.map((_, index) => (
        <AccompanistDetail key={index} index={index} districts={districts}/>
      ))}
    </div>
  );
};

export default Accompanists;
