import axios from "axios";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBProgress,
  MDBProgressBar,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CompetitionCategories } from "../../Creative/Team/Categories";
const API_URL = `${process.env.REACT_APP_BACKENE_BASE_URL}/team`;

export default function ProfilePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [teamData, setTeamData] = useState({});
  const baseURL = process.env.REACT_APP_BACKENE_BASE_URL;
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${API_URL}/${id}`, {});
        const data = response.data.data;
        setTeamData(data);
      } catch (error) {
      } finally {
      }
    };
    fetchTableData();
  }, []);
  const getCategory = () => {
    const cat = CompetitionCategories.filter(
      (cat) => cat.value == teamData.category
    )[0]?.label;
    return cat;
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  return (
    <section style={{ backgroundColor: "#eee", overflowY: "auto" }}>
      <MDBContainer className="pb-5">
        <MDBRow style={{ width: "100px" }} className="mb-4">
          <button
            type="button" // Use "button" to prevent form submission if this button is not meant to submit the form
            className="btn btn-primary"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4">
              <MDBBreadcrumbItem>
                <a href='#'>Team Profile</a>
              </MDBBreadcrumbItem>
            </MDBBreadcrumb>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="4">
            <MDBCard
              style={{ padding: "10px", height: "auto" }}
              className="mb-4 "
            >
              <div className="d-flex justify-content-center">
                <span style={{ fontWeight: "bold" }}>Team Leader</span>
              </div>
              <MDBCardBody className="text-center" style={{ padding: "10px" }}>
                <MDBCardImage
                  src={`${baseURL}/${teamData.photoPath?.replace(
                    /\\/g,
                    "/"
                  )}`}
                  alt="avatar"
                  style={{
                    width: "150px",
                    border: "1px solid black",
                    margin: "5px",
                    marginBottom: "20px",
                    cursor: "pointer"
                  }}
                  fluid
                  onClick={() => {
                    const photoUrl = `${baseURL}/${teamData.photoPath?.replace(
                      /\\/g,
                      "/"
                    )}`;
                    window.open(photoUrl, "_blank");
                  }}
                />
                <p className="text-muted mb-1">{teamData.name}</p>
                <p className="text-muted mb-4">{getCategory()}</p>
                <button
                  type="button" // Use "button" to prevent form submission if this button is not meant to submit the form
                  className="btn btn-primary"
                  onClick={() => {
                    const certificateUrl = `${baseURL}/${teamData.certificatePath?.replace(
                      /\\/g,
                      "/"
                    )}`;
                    window.open(certificateUrl, "_blank");
                  }}
                >
                  Show Certificate
                </button>
              </MDBCardBody>
            </MDBCard>

            {teamData.mediaPath && <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={() => {
                const mediaPath = `${baseURL}/${teamData.mediaPath?.replace(
                  /\\/g,
                  "/"
                )}`;
                window.open(mediaPath, "_blank");
              }}
            >
              Show Media
            </button>}

            <div
                className="d-flex justify-content-center"
                style={{ fontWeight: "bold", fontSize: "24px", marginTop: "20px" }}
              >
                Total Members: {teamData?.teamMembers?.length}
              </div>
           
          </MDBCol>

          <MDBCol lg="8">
            <MDBCard
              className="mb-4"
              style={{ padding: "10px", height: "auto" }}
            >
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Leader Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {teamData.name}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Father Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {teamData.fatherName}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Date of Birth</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {formatDate(teamData.dob)}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Email</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {teamData.email || "NA"}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Phone</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {teamData.mobileNumber}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>District</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {teamData.district}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Address</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {teamData.address}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
            {teamData?.teamMembers?.filter(acc=>acc.isLeader == false).length ?
              <div
                className="d-flex justify-content-center"
                style={{ fontWeight: "bold", fontSize: "24px" }}
              >
                Team Members
              </div>
              :<></>
            }
            <MDBRow>
              {teamData?.teamMembers?.filter(acc=>acc.isLeader == false).length  ? (
                teamData.teamMembers.filter((acc)=>acc.isLeader == false).map((accompanist) => {
                  return (
                    <MDBCard
                      md="4"
                      style={{
                        padding: "10px",
                        height: "auto",
                        width: "40%",
                      }}
                      className=" m-4"
                    >
                      <MDBCardBody
                        className="mb-4 mb-md-0"
                      >
                        <MDBCardImage
                          src={`${baseURL}/${accompanist.photoPath?.replace(
                            /\\/g,
                            "/"
                          )}`}
                          alt="avatar"
                          style={{
                            width: "150px",
                            border: "1px solid black",
                            margin: "5px",
                            marginBottom: "20px",
                            cursor: 'pointer'
                          }}
                          fluid
                          onClick={() => {
                            const photoUrl = `${baseURL}/${accompanist.photoPath?.replace(
                              /\\/g,
                              "/"
                            )}`;
                            window.open(photoUrl, "_blank");
                          }}
                        />
                        <MDBRow>
                          <MDBCol>
                            <MDBCardText>Name</MDBCardText>
                          </MDBCol>
                          <MDBCol>
                            <MDBCardText className="text-muted">
                              {accompanist.name}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol>
                            <MDBCardText>DOB</MDBCardText>
                          </MDBCol>
                          <MDBCol>
                            <MDBCardText className="text-muted">
                              {formatDate(accompanist.dob)}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol>
                            <MDBCardText>Mobile</MDBCardText>
                          </MDBCol>
                          <MDBCol>
                            <MDBCardText className="text-muted">
                              {accompanist.mobile}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol>
                            <MDBCardText>District</MDBCardText>
                          </MDBCol>
                          <MDBCol>
                            <MDBCardText className="text-muted">
                              {accompanist.district}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <button
                          type="button" // Use "button" to prevent form submission if this button is not meant to submit the form
                          className="btn btn-primary"
                          onClick={() => {
                            const certificateUrl = `${baseURL}/${accompanist.documentPath?.replace(
                              /\\/g,
                              "/"
                            )}`;
                            window.open(certificateUrl, "_blank");
                          }}
                        >
                          Show Certificate
                        </button>
                      </MDBCardBody>
                    </MDBCard>
                  );
                })
              ) : (
                <></>
              )}
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
