import React from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { CompetitionCategories } from "./Categories";


const AdditionalOptions = React.memo(
  ({ setFormData, selectedCategory, setSelectedCategory }) => {
    return (
      <div>
        <DropdownButton
          as={ButtonGroup}
          id="dropdown-button-drop-down"
          drop="down"
          variant="primary"
          size="md"
          title={
            selectedCategory
              ? CompetitionCategories.find(
                  (cat) => cat.value === selectedCategory
                )?.label || "Select Category"
              : "Select Category"
          }
          className="me-1 mb-1"
          onSelect={(category) => {
            setSelectedCategory(category);
            setFormData((prev) => ({
              ...prev,
              selectedCategory: category,
            }));
          }}
        >
          {CompetitionCategories.map((category) => (
            <Dropdown.Item key={category.value} eventKey={category.value}>
              {category.label}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    );
  }
);

export default AdditionalOptions;
